import axios from "services/axios";
import CONSTANSTS from "common/constansts";

const api = {
	get: {
		leadDetails: (payload) => axios.get(CONSTANSTS.ENDPOINT_PATH.GET_LEAD_DETAILS + payload),
		leadTasks: (payload) => axios.get(CONSTANSTS.ENDPOINT_PATH.GET_LEAD_TASKS + payload),
		matrixs: (payload) => axios.get(CONSTANSTS.ENDPOINT_PATH.GET_MATRIXS, payload),
	},
	post: {
		signIn: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.SIGN_IN, payload),
		leads: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.GET_LEADS, payload),
		createLeadTask: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.CREATE_LEAD_TASK, payload),
		createLeadNote: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.CREATE_LEAD_NOTE, payload),
		createLeadScore: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.CREATE_LEAD_SCORE, payload),
		createLeadInfo: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.CREATE_LEAD_INFO, payload),
		updateLeadInfo: (payload) => axios.post(CONSTANSTS.ENDPOINT_PATH.UPDATE_LEAD_INFO, payload),
	},
	patch: {},
};

export default api;
