import React, { memo, forwardRef, useCallback, useState, useImperativeHandle } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import pathnames from "routes/pathnames";
import AppButton from "components/app-button";

const AppLogoutModal = forwardRef((props, ref) => {
	const { t } = useTranslation("components");
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [visible, setVisible] = useState(false);

	useImperativeHandle(ref, () => ({
		onHandleDismiss: onHandleDismiss,
		onHandleShow: onHandleShow,
	}));

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	const onHandleShow = useCallback(() => {
		setVisible(true);
	}, []);

	const onHandleConfirm = useCallback(async () => {
		const logoutAction = { type: "logout" };

		localStorage.clear();
		dispatch(logoutAction);
		navigate(pathnames.signIn);
	}, [dispatch, navigate]);

	return (
		<Modal size="lg" className="app-logout-modal" contentClassName="logout-modal" centered isOpen={visible} toggle={onHandleDismiss}>
			<ModalHeader className="logout-modal__header" toggle={onHandleDismiss}></ModalHeader>
			<ModalBody className="logout-modal__body">
				<h1 className="logout-modal__title">{t("logoutModal.title")}</h1>
				<p className="logout-modal__description">{t("logoutModal.description")}</p>
			</ModalBody>
			<ModalFooter className="logout-modal__footer">
				<AppButton type="button" label={t("logoutModal.cancel")} cancel onClick={onHandleDismiss} />
				<AppButton type="button" label={t("logoutModal.cta")} onClick={onHandleConfirm} />
			</ModalFooter>
		</Modal>
	);
});

export default memo(AppLogoutModal);
