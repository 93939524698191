import React, { Fragment, useState, useMemo, useCallback, useEffect } from "react";
import * as Yup from "yup";
import numeral from "numeral";
import moment from "moment";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { BsCaretLeftFill } from "react-icons/bs";
import { useNavigate, useLocation } from "react-router-dom";

import api from "services/api";
import pathnames from "routes/pathnames";
import CONSTANSTS from "common/constansts";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import AppDropdown from "components/app-dropdown";
import AppRadioInput from "components/app-radio-input";
import AppDateInput from "components/app-date-input";
import AppCurrencyInput from "components/pages/page-lead-maintence/app-currency-input";
import AppLengthOfBusinessInput from "components/pages/page-lead-maintence/app-length-of-business-input";
import { onHandleRequestError, onHandleRequestSuccess } from "common/utilities";
import usePrevious from "hooks/use-previous";

export const moneyMask = (price) => {
	return numeral(price).format("0,0.00");
};

const PageNewLead = () => {
	const navigate = useNavigate();
	const { state } = useLocation();
	const profile = useSelector((state) => state.auth.profile);
	const [formType, setFormType] = useState(state?.category || "");
	const isCreateMode = state?.mode === CONSTANSTS.MODE.CREATE;
	const isEditode = state?.mode === CONSTANSTS.MODE.EDIT;
	const initialValues = useMemo(() => {
		const values = {
			age: "",
			category: "",
			com_address_1: "",
			com_address_2: "",
			com_postcode: "",
			com_state: "",
			comp_size: "",
			dependent_num: "",
			email: "",
			employer_nm: "",
			employment_type: "",
			gender: "",
			gross_income: "",
			marital_status: "",
			nature_of_business: "",
			nm: "",
			occupation: "",
			phone: "",
			pri_address_1: "",
			pri_address_2: "",
			pri_postcode: "",
			pri_state: "",
			product: "",
			race: "",
			sec_address_1: "",
			sec_address_2: "",
			sec_postcode: "",
			sec_state: "",
			source: "",
			year_of_business: "",
			agent_id: profile.id,
			agent_nm: profile.user_name,
		};

		if (state) {
			if (state.age) values.age = state.age;
			if (state.category) values.category = state.category;
			if (state.com_address_1) values.com_address_1 = state.com_address_1;
			if (state.com_address_2) values.com_address_2 = state.com_address_2;
			if (state.com_postcode) values.com_postcode = state.com_postcode;
			if (state.com_state) values.com_state = CONSTANSTS.OPTIONS.STATES.filter((o) => o.value === state.com_state)[0];
			if (state.comp_size) values.comp_size = CONSTANSTS.OPTIONS.COMPANY_SIZE.filter((o) => o.value === state.comp_size)[0];
			if (state.dependent_num) values.dependent_num = state.dependent_num;
			if (state.email) values.email = state.email;
			if (state.employer_nm) values.employer_nm = state.employer_nm;
			if (state.employment_type) values.employment_type = CONSTANSTS.OPTIONS.EMPLOYMENT_TYPE.filter((o) => o.value === state.employment_type)[0];
			if (state.gender) values.gender = CONSTANSTS.OPTIONS.GENDER.filter((o) => o.value === state.gender)[0];
			if (state.gross_income) values.gross_income = moneyMask(state.gross_income);
			if (state.marital_status) values.marital_status = CONSTANSTS.OPTIONS.MARITAL_STATUS.filter((o) => o.value === state.marital_status)[0];
			if (state.nature_of_business) values.nature_of_business = CONSTANSTS.OPTIONS.NATURAL_OF_BUSINESS.filter((o) => o.value === state.nature_of_business)[0];
			if (state.nm) values.nm = state.nm;
			if (state.occupation) values.occupation = state.occupation;
			if (state.phone) values.phone = state.phone;
			if (state.pri_address_1) values.pri_address_1 = state.pri_address_1;
			if (state.pri_address_2) values.pri_address_2 = state.pri_address_2;
			if (state.pri_postcode) values.pri_postcode = state.pri_postcode;
			if (state.pri_state) values.pri_state = CONSTANSTS.OPTIONS.STATES.filter((o) => o.value === state.pri_state)[0];
			if (state.product) values.product = CONSTANSTS.OPTIONS.PRODUCTS.filter((o) => o.value === state.product)[0];
			if (state.race) values.race = CONSTANSTS.OPTIONS.RACE.filter((o) => o.value === state.race)[0];
			if (state.sec_address_1) values.sec_address_1 = state.sec_address_1;
			if (state.sec_address_2) values.sec_address_2 = state.sec_address_2;
			if (state.sec_postcode) values.sec_postcode = state.sec_postcode;
			if (state.sec_state) values.sec_state = CONSTANSTS.OPTIONS.STATES.filter((o) => o.value === state.sec_state)[0];
			if (state.source) values.source = CONSTANSTS.OPTIONS.SOURCES.filter((o) => o.value === state.source)[0];
			if (state.year_of_business) values.year_of_business = state.year_of_business;
			if (state.dt_birth) values.dt_birth = new Date(state.dt_birth);

			const primaryAddress = state.pri_address_1 || state.pri_address_2 || state.pri_postcode || state.pri_state;
			const secAddress = state.sec_address_1 || state.sec_address_2 || state.sec_postcode || state.sec_state;

			if (primaryAddress && secAddress) {
				let sameAddress = false;
				state.pri_address_1 === state.sec_address_1 ? (sameAddress = true) : (sameAddress = false);
				state.pri_address_2 === state.sec_address_2 ? (sameAddress = true) : (sameAddress = false);
				state.pri_postcode === state.sec_postcode ? (sameAddress = true) : (sameAddress = false);
				state.pri_state === state.sec_state ? (sameAddress = true) : (sameAddress = false);

				values.sameAsResidential = sameAddress;
			}
		}

		return values;
	}, [state, profile]);
	const formik = useFormik({
		enableReinitialize: true,
		initialValues,
		validationSchema: () => {
			if (formik.values?.category === "01") {
				return Yup.object().shape({
					category: Yup.string().required("This field is required"),
					product: Yup.object().required("This field is required"),
					source: Yup.object().required("This field is required"),
					nm: Yup.string().required("This field is required"),
					gender: Yup.object().required("This field is required"),
					dt_birth: Yup.date().required("This field is required"),
					race: Yup.object().required("This field is required"),
					marital_status: Yup.object().required("This field is required"),
					dependent_num: Yup.number().when("marital_status", {
						is: (opt) => opt?.value !== "01",
						then: Yup.number().required("This field is required").typeError("This field is required"),
					}),
					pri_address_1: Yup.string().required("This field is required"),
					pri_postcode: Yup.number().required("This field is required").typeError("This field is required"),
					pri_state: Yup.object().required("This field is required"),
					sec_address_1: Yup.string().required("This field is required"),
					sec_postcode: Yup.number().required("This field is required").typeError("This field is required"),
					sec_state: Yup.object().required("This field is required"),
					email: Yup.string().email("This field is required").required("This field is required"),
					phone: Yup.string().required("This field is required"),
					employment_type: Yup.object().required("This field is required"),
					occupation: Yup.string().required("This field is required"),
					gross_income: Yup.string().required("This field is required"),
					employer_nm: Yup.string().required("This field is required"),
					com_address_1: Yup.string().required("This field is required"),
					com_postcode: Yup.string().required("This field is required"),
					com_state: Yup.object().required("This field is required"),
				});
			} else {
				return Yup.object().shape({
					category: Yup.string().required("This field is required"),
					source: Yup.object().required("This field is required"),
					product: Yup.object().required("This field is required"),
					nm: Yup.string().required("This field is required"),
					email: Yup.string().email("This field is required").required("This field is required"),
					comp_size: Yup.object().required("This field is required"),
					year_of_business: Yup.number().required("This field is required").typeError("This field is required"),
					nature_of_business: Yup.object().required("This field is required"),
					phone: Yup.string().required("This field is required"),
					com_address_1: Yup.string().required("This field is required"),
					com_address_2: Yup.string(),
					com_postcode: Yup.number().required("This field is required").typeError("This field is required"),
					com_state: Yup.object().required("This field is required"),
				});
			}
		},
		onSubmit: (values) => {
			onHandleConfirmCreate(values);
		},
	});
	const previousForm = usePrevious(formik.values);

	const form = useMemo(() => {
		const getFormFields = () => {
			if (formType === "01") {
				let secondAddressFields = null;
				if (formik.values.sameAsResidential) {
					secondAddressFields = [
						{
							sectionDescription: "Permanent Address",
							name: "sameAsResidential",
							label: "Same as Residential Address",
							placeholder: "",
							disabled: false,
							type: "checkbox",
						},
					];
				} else {
					secondAddressFields = [
						{
							sectionDescription: "Permanent Address",
							name: "sameAsResidential",
							label: "Same as Residential Address",
							placeholder: "",
							disabled: false,
							type: "checkbox",
						},
						{
							name: "sec_address_1",
							label: "Address Line 1",
							placeholder: "",
							disabled: false,
							type: "text",
						},
						{
							name: "sec_address_2",
							label: "Address Line 2",
							placeholder: "Optional",
							disabled: false,
							type: "text",
						},
						{
							name: "sec_postcode",
							label: "Postcode",
							placeholder: "",
							maxLength: 5,
							disabled: false,
							type: "text",
						},
						{
							name: "sec_state",
							label: "State",
							placeholder: "Please Select",
							options: CONSTANSTS.OPTIONS.STATES,
							disabled: false,
							type: "select",
						},
					];
				}

				return [
					{
						sectionTitle: "Personal Details",
						name: "nm",
						label: "Name",
						placeholder: "",
						disabled: isEditode,
						type: "text",
					},
					{
						name: "gender",
						label: "Gender",
						placeholder: "Please Select",
						options: CONSTANSTS.OPTIONS.GENDER,
						disabled: false,
						type: "select",
					},
					{
						name: "dt_birth",
						label: "Date of Birth",
						placeholder: "",
						maxDate: new Date(),
						disabled: false,
						type: "date",
					},
					{
						name: "age",
						label: "Age",
						placeholder: "",
						disabled: true,
						type: "text",
					},
					{
						name: "race",
						label: "Race",
						placeholder: "Please Select",
						options: CONSTANSTS.OPTIONS.RACE,
						disabled: false,
						type: "select",
					},
					{
						name: "marital_status",
						label: "Marital Status",
						placeholder: "Please Select",
						options: CONSTANSTS.OPTIONS.MARITAL_STATUS,
						disabled: false,
						type: "select",
					},
					formik.values?.marital_status?.value !== "01" && {
						name: "dependent_num",
						label: "No. of Dependent",
						placeholder: "",
						disabled: false,
						type: "text",
					},
					{
						sectionTitle: "Contact Info",
						sectionDescription: "Residential Address",
						name: "pri_address_1",
						label: "Address Line 1",
						placeholder: "",
						disabled: false,
						type: "text",
					},
					{
						name: "pri_address_2",
						label: "Address Line 2",
						placeholder: "Optional",
						disabled: false,
						type: "text",
					},
					{
						name: "pri_postcode",
						label: "Postcode",
						placeholder: "",
						maxLength: 5,
						disabled: false,
						type: "text",
					},
					{
						name: "pri_state",
						label: "State",
						placeholder: "Please Select",
						options: CONSTANSTS.OPTIONS.STATES,
						disabled: false,
						type: "select",
					},
					...secondAddressFields,
					{
						name: "email",
						label: "Email Address",
						placeholder: "",
						disabled: false,
						type: "text",
					},
					{
						name: "phone",
						label: "Contact No.",
						placeholder: "",
						disabled: false,
						type: "text",
					},
					{
						sectionTitle: "Employment Details",
						name: "employment_type",
						label: "Employment Type",
						placeholder: "Please Select",
						options: CONSTANSTS.OPTIONS.EMPLOYMENT_TYPE,
						disabled: false,
						type: "select",
					},
					{
						name: "occupation",
						label: "Job Title",
						placeholder: "",
						disabled: false,
						type: "text",
					},
					{
						name: "gross_income",
						label: "Monthly Gross / Net Income",
						placeholder: "",
						disabled: false,
						type: "text",
					},
					{
						name: "employer_nm",
						label: "Employer Name",
						placeholder: "",
						disabled: false,
						type: "text",
					},
					{
						sectionDescription: "Office Address",
						name: "com_address_1",
						label: "Address Line 1",
						placeholder: "",
						disabled: false,
						type: "text",
					},
					{
						name: "com_address_2",
						label: "Address Line 2",
						placeholder: "Optional",
						disabled: false,
						type: "text",
					},
					{
						name: "com_postcode",
						label: "Postcode",
						placeholder: "",
						maxLength: 5,
						disabled: false,
						type: "text",
					},
					{
						name: "com_state",
						label: "State",
						placeholder: "Please Select",
						options: CONSTANSTS.OPTIONS.STATES,
						disabled: false,
						type: "select",
					},
				];
			} else {
				return [
					{
						sectionTitle: "Company Details",
						name: "nm",
						label: "Company Name",
						placeholder: "",
						disabled: isEditode,
						type: "text",
					},
					{
						name: "comp_size",
						label: "Company Size",
						placeholder: "Please Select",
						options: CONSTANSTS.OPTIONS.COMPANY_SIZE,
						disabled: false,
						type: "select",
					},
					{
						name: "year_of_business",
						label: "Length of Business",
						placeholder: "",
						disabled: false,
						type: "text",
					},
					{
						name: "nature_of_business",
						label: "Nature of Business",
						placeholder: "Please Select",
						options: CONSTANSTS.OPTIONS.NATURAL_OF_BUSINESS,
						disabled: false,
						type: "select",
					},
					{
						sectionTitle: "Contact Info",
						sectionDescription: "Company Address",
						name: "com_address_1",
						label: "Address Line 1",
						placeholder: "",
						disabled: false,
						type: "text",
					},
					{
						name: "com_address_2",
						label: "Address Line 2",
						placeholder: "Optional",
						disabled: false,
						type: "text",
					},
					{
						name: "com_postcode",
						label: "Postcode",
						placeholder: "",
						disabled: false,
						maxLength: 5,
						type: "text",
					},
					{
						name: "com_state",
						label: "State",
						placeholder: "Please Select",
						options: CONSTANSTS.OPTIONS.STATES,
						disabled: false,
						type: "select",
					},
					{
						name: "email",
						label: "Company Email Address",
						placeholder: "",
						disabled: false,
						type: "text",
					},
					{
						name: "phone",
						label: "Contact No.",
						placeholder: "",
						disabled: false,
						type: "text",
					},
				];
			}
		};
		const fields = [
			{
				name: "product",
				label: "Product",
				placeholder: "Please Select",
				options: CONSTANSTS.OPTIONS.PRODUCTS,
				disabled: false,
				type: "select",
			},
			{
				name: "source",
				label: "Source",
				placeholder: "Please Select",
				options: CONSTANSTS.OPTIONS.SOURCES,
				disabled: false,
				type: "select",
			},

			...getFormFields(),
		];

		return fields;
	}, [isEditode, formik, formType]);

	useEffect(() => {
		const values = formik.values;

		const primaryAddressIsValid = values.pri_address_1 && values.pri_postcode && values.pri_state;
		const secondAddressIsNull = !values.sec_address_1 || !values.sec_postcode || !values.sec_state;
		const secondAddressIsValid = values.sec_address_1 || values.sec_postcode || values.sec_state;

		if (values.sameAsResidential) {
			if (!!primaryAddressIsValid && secondAddressIsNull) {
				formik.setFieldValue("sec_address_1", values.pri_address_1);
				formik.setFieldValue("sec_address_2", values.pri_address_2);
				formik.setFieldValue("sec_postcode", values.pri_postcode);
				formik.setFieldValue("sec_state", values.pri_state);
			}
		} else {
			if (secondAddressIsValid) {
				formik.setFieldValue("sec_address_1", "");
				formik.setFieldValue("sec_address_2", "");
				formik.setFieldValue("sec_postcode", "");
				formik.setFieldValue("sec_state", "");
			}
		}
	}, [formik]);
	console.log(formik);
	useEffect(() => {
		const values = formik.values;

		if (previousForm) {
			const prevDate = moment(previousForm?.dt_birth).format(CONSTANSTS.DATE_FORMAT.YYYYMMDDHH_MMA);
			const currentDate = moment(values.dt_birth).format(CONSTANSTS.DATE_FORMAT.YYYYMMDDHH_MMA);

			if (prevDate !== currentDate) {
				const currentYear = new Date().getFullYear();
				const dob = values.dt_birth.getFullYear();

				formik.setFieldValue("age", currentYear - dob);
			}
		}
	}, [previousForm, formik]);

	const onHandleConfirmCreate = useCallback(
		async ({ sameAsResidential, ...values }) => {
			let response = null;

			try {
				let payload = null;

				if (values.category === "01") {
					payload = {
						...values,
						gender: values.gender.value,
						race: values.race.value,
						marital_status: values.marital_status.value,
						dt_birth: moment(values.dt_birth).format(CONSTANSTS.DATE_FORMAT.DOB_FORMAT),
						product: values.product.value,
						source: values.source.value,
						pri_state: values.pri_state.value,
						sec_state: values.sec_state.value,
						employment_type: values.employment_type.value,
						com_state: values.com_state.value,
						age: +values.age,
						dependent_num: values.marital_status.value === "01" ? 0 : +values.dependent_num,
						gross_income: parseInt(values.gross_income.replace(/,/g, "")),
					};
				} else {
					payload = {
						...values,
						comp_size: values.comp_size.value,
						com_state: values.com_state.value,
						product: values.product.value,
						nature_of_business: values.nature_of_business.value,
						source: values.source.value,
						year_of_business: +values.year_of_business,
						marital_status: "",
						pri_state: "",
						sec_state: "",
						employment_type: "",
						gross_income: 0,
						age: 0,
						race: "",
						gender: "",
					};
				}

				if (isEditode) {
					payload.cd = state.cd;
					payload.modified_by = profile.id;
					response = await api.post.updateLeadInfo(payload);
				}

				if (isCreateMode) {
					payload.created_by = profile.id;
					response = await api.post.createLeadInfo(payload);
				}
			} catch (error) {
				onHandleRequestError(error);
			} finally {
				formik.setSubmitting(false);
			}

			if (response) {
				if (isEditode) {
					onHandleRequestSuccess("Lead has updated successfully!");
				}

				if (isCreateMode) {
					onHandleRequestSuccess("New Lead has created successfully!");
				}
				navigate(pathnames.leadMaintence);
			}
		},
		[formik, state, navigate, isEditode, isCreateMode, profile]
	);

	const onHandleSetFormType = (type, value) => {
		setFormType(value);
		formik.resetForm();
		formik.setFieldValue(type, value);
	};

	const onNavigateGoBack = useCallback(() => {
		if (isEditode) {
			navigate(pathnames.customer, { state });
		} else {
			navigate(pathnames.leadMaintence);
		}
	}, [isEditode, navigate]);

	return (
		<div className="page-new-lead">
			<div className="new-lead">
				<form autoComplete="off" onSubmit={formik.handleSubmit}>
					<div className="new-lead__header">
						<div className="new-lead__header-wrapper">
							<button className="new-lead__back-button" type="button" onClick={onNavigateGoBack}>
								<BsCaretLeftFill />
							</button>
							<h1 className="new-lead__title">{isCreateMode ? "Add New Lead" : "Edit New Lead"}</h1>
						</div>
						<div className="new-lead__button-container">
							<AppButton label="Cancel" type="button" cancel onClick={onNavigateGoBack} />
							<AppButton label="Submit" type="submit" loading={formik.isSubmitting} />
						</div>
					</div>
					<div className="new-lead__card">
						{isCreateMode && (
							<div className="new-lead__form-option">
								<AppRadioInput
									name="category"
									value={formType}
									onChange={onHandleSetFormType}
									options={[
										{ label: "For Individual", value: "01" },
										{ label: "For Company", value: "02" },
									]}
								/>
							</div>
						)}

						{formType &&
							form.map(({ label, important, sectionTitle, sectionDescription, ...res }, i) => {
								const value = formik.values[res.name];
								const error = formik.errors[res.name];
								const touched = formik.touched[res.name];
								const isRadioField = res.type === "radio";
								const isDateField = res.type === "date";
								const isDropdownField = res.type === "select";
								const isCheckboxField = res.type === "checkbox";
								const isLengthOfBusinessField = res.name === "year_of_business";
								const isMonthlyIncome = res.name === "gross_income";

								if (!res.name) return null;

								if (isDropdownField) {
									return (
										<Fragment key={i}>
											{sectionTitle ? <p className="new-lead__section">{sectionTitle}</p> : null}
											<div className="new-lead__field">
												<p className="new-lead__label">{label}</p>
												<AppDropdown {...res} value={value} error={error} touched={touched} data={res.options} onChange={formik.setFieldValue} />
											</div>
										</Fragment>
									);
								}

								if (isRadioField) {
									return (
										<div key={i} className="new-lead__field">
											<p className="new-lead__label">{label}</p>
											<AppRadioInput {...res} value={value} onChange={formik.setFieldValue} />
										</div>
									);
								}

								if (isDateField) {
									return (
										<div key={i} className="new-lead__field">
											<p className="new-lead__label">{label}</p>
											<AppDateInput {...res} value={value} error={error} touched={touched} onChange={formik.setFieldValue} />
										</div>
									);
								}

								if (isCheckboxField) {
									return (
										<Fragment key={i}>
											{sectionDescription ? <p className="new-lead__description">{sectionDescription}</p> : null}
											<div key={i} className="new-lead__field new-lead__field--checkbox">
												<AppCheckbox {...res} checked={value} onChange={formik.handleChange} />
												<p className="new-lead__label">{label}</p>
											</div>
										</Fragment>
									);
								}

								if (isLengthOfBusinessField) {
									return (
										<Fragment key={i}>
											<div className="new-lead__field">
												<p className="new-lead__label">{label}</p>
												<AppLengthOfBusinessInput {...res} value={value} error={error} touched={touched} onChange={formik.handleChange} />
											</div>
										</Fragment>
									);
								}

								if (isMonthlyIncome) {
									return (
										<Fragment key={i}>
											<div className="new-lead__field">
												<p className="new-lead__label">{label}</p>
												<AppCurrencyInput {...res} value={value} error={error} touched={touched} onChange={formik.handleChange} />
											</div>
										</Fragment>
									);
								}

								return (
									<Fragment key={i}>
										{sectionTitle ? <p className="new-lead__section">{sectionTitle}</p> : null}
										{sectionDescription ? <p className="new-lead__description">{sectionDescription}</p> : null}
										<div className="new-lead__field">
											<p className="new-lead__label">{label}</p>
											<AppInput {...res} value={value} error={error} touched={touched} onChange={formik.handleChange} />
										</div>
									</Fragment>
								);
							})}
					</div>
				</form>
			</div>
		</div>
	);
};

export default PageNewLead;
