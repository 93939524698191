import React, { useMemo, useState, useRef, useCallback, useEffect, Fragment } from "react";
import moment from "moment";
import { debounce } from "lodash";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";

import api from "services/api";
import pathnames from "routes/pathnames";
import CONSTANSTS from "common/constansts";
import useIsMount from "hooks/use-is-mount";
import AppButton from "components/app-button";
import AppEmptyData from "components/app-empty-data";
import AppTable, { TableStatus, TableEditButton } from "components/app-table";
import { onHandleRequestError } from "common/utilities";

const PageLeadInquiry = (props) => {
	const navigate = useNavigate();
	const isMount = useIsMount();
	const profile = useSelector((state) => state.auth.profile);
	const [customers, setCustomers] = useState(null);
	const [totalPage, setTotalPage] = useState(1);
	const isAdmin = profile.user_role === CONSTANSTS.ROLE.ADMIN;
	const filterBy = useRef({ length: 10, page: 0, value: "", sortBy: "", status: !isAdmin ? "02" : "" });
	const isEmpty = !customers?.length || !customers;
	const isEmptySearch = !customers?.length && filterBy.current.value;

	const getLeads = useCallback(async () => {
		let response = null;

		try {
			const payload = {
				result_offset: filterBy.current.page === 0 ? 0 : filterBy.current.page * 10,
				result_size: filterBy.current.length,
				filter_criteria: filterBy.current.value,
				sort_criteria: filterBy.current.sortBy,
				status_filter: filterBy.current.status,
			};

			response = await api.post.leads(payload);
		} catch (error) {}

		if (response) {
			setCustomers(response.data.leads);
			setTotalPage(Math.round(response.data.result_info.total_record / 10));
		}
	}, []);

	useEffect(() => {
		if (isMount) {
			getLeads();
		}
	}, [isMount, getLeads]);

	useEffect(() => {
		return () => props.onHandleCancelRequest();
	}, [props]);

	//prettier-ignore
	const onHandleSortTable = useCallback((order, key) => {
			filterBy.current.page = 0;
			filterBy.current.sortBy = `${key} ${order.toUpperCase()} `;
			getLeads();
		}, [getLeads]
	);

	//prettier-ignore
	const columns = useMemo(() => [
			{
				Header: "Lead ID",
				accessor: "cd",
			},
			{
				Header: "Lead Name",
				accessor: "nm",
				setFilter: (value) => {
					onHandleSortTable(value, "nm");
				},
			},
			{
				Header: "Lead Type",
				accessor: "category",
				Cell: ({ cell: { value } }) => CONSTANSTS.OPTIONS.LEAD_TYPE.filter(o => o.value === value)?.[0]?.label || "N/A",
				setFilter: (value) => {
					onHandleSortTable(value, "category");
				},
			},
			{
				Header: "Product",
				accessor: "product",
				Cell: ({ cell: { value } }) => CONSTANSTS.OPTIONS.PRODUCTS.filter(o => o.value === value)?.[0]?.label || "N/A",
				setFilter: (value) => {
					onHandleSortTable(value, "product");
				},
			},
			{
				Header: "Assigned To",
				accessor: "agent_nm",
			},
			{
				Header: "Added Date",
				accessor: "dt_agent_assignment",
				Cell: ({ cell: { value } }) => moment(value).format(CONSTANSTS.DATE_FORMAT.DDMMMYYYY),
				setFilter: (value) => {
					onHandleSortTable(value, "dt_agent_assignment");
				},
			},
			{
				Header: "Last Interaction",
				accessor: "dt_modified",
				Cell: ({ cell: { value } }) => moment(value).format(CONSTANSTS.DATE_FORMAT.DDMMMYYYY),
				setFilter: (value) => {
					onHandleSortTable(value, "dt_modified");
				},
			},
			{
				Header: "Status",
				accessor: "status",
				filterOptions: isAdmin ? CONSTANSTS.OPTIONS.LEADS_STATUS : null,
				Cell: ({ cell: { value } }) => <TableStatus status={value}/>,
				setFilter: (value) => {
					if(isAdmin) {
						filterBy.current.page = 0;
						filterBy.current.status = value;
						getLeads();
					}
					else {
						onHandleSortTable(value, "status");
					}
				},
			},
			{
				Header: "",
				accessor: "t-action",
				Cell: ({ row }) => {
					const customer = row.original;
					return (
						<TableEditButton
							onClick={() => {
								navigate(pathnames.customer, { state: { ...customer } });
							}}
						/>
					);
				},
			},
		],
		 [isAdmin, getLeads, onHandleSortTable, navigate]
	);

	const onChangePagination = (event) => {
		filterBy.current.page = +event.target.value;
		getLeads();
	};

	const onSearchCustomer = (event) => {
		const value = event.target.value;
		filterBy.current = { ...filterBy.current, length: 10, page: 0, value: value };
		getLeads();
	};

	const onHandleSearchCustomer = debounce(onSearchCustomer, 2000);

	return (
		<div className="page-lead-maintence">
			<div className="lead-maintence">
				<h1 className="lead-maintence__title">Lead Inquiry / Tracking</h1>

				<div className="lead-maintence__header">
					<p className="lead-maintence__description"></p>
					<div className="lead-maintence__search">
						<input size="30" name="search" placeholder="Search by Lead ID or Lead Name" onChange={onHandleSearchCustomer} />
						<IoSearchOutline />
						{profile.user_role === CONSTANSTS.ROLE.ADMIN && (
							<Fragment>
								<AppButton type="button" label="Export" outline onClick={() => {}} />
								<AppButton type="button" label="Add New Lead" onClick={() => navigate(pathnames.newLead, { state: { mode: CONSTANSTS.MODE.CREATE } })} />
							</Fragment>
						)}
					</div>
				</div>

				<div className="lead-maintence__body">
					{(isEmpty || isEmptySearch) && <AppEmptyData data={customers} search={isEmptySearch} />}
					{!isEmpty && customers && <AppTable columns={columns} data={customers} total={totalPage} page={filterBy.current.page} onChangePagination={onChangePagination} />}
				</div>
			</div>
		</div>
	);
};

export default PageLeadInquiry;
