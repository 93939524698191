import React, { useMemo, useRef } from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend } from "chart.js";
import { Bar, Doughnut } from "react-chartjs-2";
import dropIcon from "assets/images/drop-icon.svg";
import raiseIcon from "assets/images/raise-icon.svg";
import AppTable, { TableEditButton } from "components/app-table";

ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend);

export const options = {
	responsive: true,
	plugins: {
		legend: {
			display: false,
		},
		scales: {
			xAxes: [
				{
					gridLines: {
						display: false,
					},
				},
			],
			yAxes: [
				{
					gridLines: {
						display: false,
					},
				},
			],
		},
	},
};
export const doughnutOptions_1 = {
	responsive: true,
	plugins: {
		legend: {
			display: false,
		},
		scales: {
			xAxes: [
				{
					gridLines: {
						display: false,
					},
				},
			],
			yAxes: [
				{
					gridLines: {
						display: false,
					},
				},
			],
		},
	},
};
export const doughnutOptions = {
	responsive: true,
	rotation: -90,
	circumference: 180,
	plugins: {
		legend: {
			display: false,
		},
		scales: {
			xAxes: [
				{
					gridLines: {
						display: false,
					},
				},
			],
			yAxes: [
				{
					gridLines: {
						display: false,
					},
				},
			],
		},
	},
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];
export const data = {
	labels,
	datasets: [
		{
			label: "Facebook",
			data: [1, 2, 3, 4, 5, 6, 7],
			backgroundColor: "#64C2DB",
		},
		{
			label: "Instagram",
			data: [1, 2, 3, 4, 5, 6, 7],
			backgroundColor: "#7476ED",
		},
		{
			label: "Website",
			data: [1, 2, 3, 4, 5, 6, 7],
			backgroundColor: "#E56F8C",
		},
	],
};

const branchesData = {
	labels: ["Branch A", "Branch B", "Branch C"],
	datasets: [
		{
			data: [12, 19, 3],
			backgroundColor: ["#64C2DB", "#7476ED", "#E56F8C"],
		},
	],
};

const salesData = {
	labels: ["Area A", "Area B", "Area C"],
	datasets: [
		{
			data: [12, 19, 3],
			backgroundColor: ["#64C2DB", "#7476ED", "#E56F8C"],
		},
	],
};

export const productData = {
	labels: ["Converted"],
	datasets: [
		{
			data: [68, 32],
			backgroundColor: ["#004F91", "#F9F9F9"],
		},
	],
};

const PageDashboard = () => {
	const barChartRef = useRef();
	const doughnutBestBranchesChartRef = useRef();
	const doughnutChartRef = useRef();

	const onHandleToggleBarChart = (i) => (event) => {
		const visibleChart = barChartRef.current.isDatasetVisible(i);

		if (visibleChart) {
			barChartRef.current.hide(i);
			event.currentTarget.lastChild.style.textDecoration = "line-through";
		} else {
			barChartRef.current.show(i);
			event.currentTarget.lastChild.style.textDecoration = "none";
		}
	};

	const onHandleToggleDoughutChart = (i, ref) => (event) => {
		ref.current.toggleDataVisibility(i);
		ref.current.update();
	};

	const columns = useMemo(
		() => [
			{
				Header: "Product Name",
				accessor: "productName",
			},
			{
				Header: "New Inquiry",
				accessor: "inquiry",
			},
			{
				Header: "Volume",
				accessor: "volume",
			},
			{
				Header: "",
				accessor: "t-action",
				Cell: ({ row }) => {
					return <TableEditButton onClick={() => {}} />;
				},
			},
		],
		[]
	);

	return (
		<div className="page-dashboard">
			<div className="dashboard">
				<h1 className="dashboard__title">Dashboard</h1>

				<div className="dashboard__header">
					<ul className="dashboard__list">
						<li className="dashboard__item">
							<div className="dashboard__content">
								<p className="dashboard__label">Total of Leads</p>
								<p className="dashboard__text">1,024</p>
							</div>
							<div className="dashboard__content dashboard__content--value">
								<img src={raiseIcon} alt="" />
								<p className="dashboard__percent dashboard__percent--green">1.8%</p>
							</div>
						</li>
						<li className="dashboard__item">
							<div className="dashboard__content">
								<p className="dashboard__label">New Leads</p>
								<p className="dashboard__text">224</p>
							</div>
							<div className="dashboard__content dashboard__content--value">
								<img src={raiseIcon} alt="" />
								<p className="dashboard__percent dashboard__percent--green">1.8%</p>
							</div>
						</li>
						<li className="dashboard__item">
							<div className="dashboard__content">
								<p className="dashboard__label">Converted</p>
								<p className="dashboard__text">224</p>
							</div>
							<div className="dashboard__content dashboard__content--value">
								<img src={dropIcon} alt="" />
								<p className="dashboard__percent dashboard__percent--red">1.8%</p>
							</div>
						</li>
						<li className="dashboard__item">
							<div className="dashboard__content">
								<p className="dashboard__label">Follow-up</p>
								<p className="dashboard__text">224</p>
							</div>
							<div className="dashboard__content dashboard__content--value">
								<img src={raiseIcon} alt="" />
								<p className="dashboard__percent dashboard__percent--green">1.8%</p>
							</div>
						</li>
						<li className="dashboard__item">
							<div className="dashboard__content">
								<p className="dashboard__label">Low Rate</p>
								<p className="dashboard__text">20</p>
							</div>
							<div className="dashboard__content dashboard__content--value">
								<img src={raiseIcon} alt="" />
								<p className="dashboard__percent dashboard__percent--green">1.8%</p>
							</div>
						</li>
					</ul>
				</div>

				<div className="dashboard__chart-container">
					<div className="dashboard__top-lead">
						<div className="chart">
							<p className="chart__title">Top 3 Best Lead Sources</p>
							<ul className="chart__legend">
								{data.datasets.map((o, i) => {
									return (
										<li className="chart__legend-item" key={i} onClick={onHandleToggleBarChart(i)}>
											<div className="chart__badge" style={{ backgroundColor: o.backgroundColor }} />
											<p className="chart__label">{o.label}</p>
										</li>
									);
								})}
							</ul>
							<Bar ref={barChartRef} options={options} data={data} />
						</div>
					</div>

					<div className="dashboard__best-branches">
						<div className="chart">
							<p className="chart__title">Top 3 Best Branches</p>
							<ul className="chart__legend">
								{branchesData.labels.map((o, i) => {
									return (
										<li className="chart__legend-item" key={i} onClick={onHandleToggleDoughutChart(i, doughnutBestBranchesChartRef)}>
											<div className="chart__badge" style={{ backgroundColor: branchesData.datasets[0].backgroundColor[i] }} />
											<p className="chart__label">{o}</p>
										</li>
									);
								})}
							</ul>
							<Doughnut ref={doughnutBestBranchesChartRef} options={doughnutOptions_1} data={branchesData} />
						</div>
					</div>

					<div className="dashboard__most-profit-product">
						<div className="chart">
							<p className="chart__title">Most Profit Product</p>
							<p className="chart__description">Personal Financing</p>
							<Doughnut options={doughnutOptions} data={productData} />
							<div className="chart__profit-value">
								<p className="dashboard__text">68%</p>
								<p className="dashboard__label">Converted</p>
							</div>
							<div className="chart__footer">
								<div className="chart__content">
									<p className="dashboard__label">Total Applications</p>
									<p className="dashboard__text">224</p>
								</div>
								<div className="chart__content">
									<img src={raiseIcon} alt="" />
									<p className="dashboard__percent dashboard__percent--green">1.8%</p>
								</div>
							</div>
						</div>
					</div>

					<div className="dashboard__best-branches">
						<div className="chart">
							<p className="chart__title">Potential Sales Areas (Inquiry)</p>
							<ul className="chart__legend">
								{branchesData.labels.map((o, i) => {
									return (
										<li className="chart__legend-item" key={i} onClick={onHandleToggleDoughutChart(i, doughnutChartRef)}>
											<div className="chart__badge" style={{ backgroundColor: branchesData.datasets[0].backgroundColor[i] }} />
											<p className="chart__label">{o}</p>
										</li>
									);
								})}
							</ul>
							<Doughnut ref={doughnutChartRef} options={doughnutOptions_1} data={salesData} />
						</div>
					</div>

					<div className="dashboard__best-cross-selling">
						<div className="chart">
							<p className="chart__title">Best Cross Selling</p>
							<AppTable
								columns={columns}
								data={[
									{
										productName: "Campaign A",
										inquiry: "234",
										volume: "33.6%",
									},
									{
										productName: "Product A",
										inquiry: "200",
										volume: "16.6%",
									},
									{
										productName: "Campaign B",
										inquiry: "100",
										volume: "23.6%",
									},
									{
										productName: "Campaign C",
										inquiry: "80",
										volume: "12.3%",
									},
									{
										productName: "Product B",
										inquiry: "60",
										volume: "12.4%",
									},
									{
										productName: "Campaign D",
										inquiry: "59",
										volume: "17.8%",
									},
								]}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PageDashboard;
