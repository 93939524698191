import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import bm from "locales/bm";
import en from "locales/en";

const i18n = i18next.createInstance();

const getUserLanguage = () => {
	const defaultLng = "en";
	const userLng = localStorage.getItem("@menara-user-language");
	if (userLng) return userLng;

	localStorage.setItem("@menara-user-language", defaultLng);
	return defaultLng;
};

i18n.use(initReactI18next).init({
	debug: !process.env.NODE_ENV || process.env.NODE_ENV === "development",
	second: true,
	preload: true,
	resources: { bm, en },
	lng: getUserLanguage(),
	fallbackLng: "en",
	interpolation: {
		escapeValue: false,
	},
});

export default i18n;
