import React, { useEffect, useRef, useMemo, useState, useCallback } from "react";
import * as Yup from "yup";
import moment from "moment";
import { useFormik } from "formik";
import classnames from "classnames";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { IoMail, IoCall, IoCheckmarkCircleOutline, IoCloseCircleOutline } from "react-icons/io5";
import { BsCaretLeftFill, BsThreeDotsVertical } from "react-icons/bs";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";

import api from "services/api";
import pathnames from "routes/pathnames";
import CONSTANSTS from "common/constansts";
import useIsMount from "hooks/use-is-mount";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppDropdown from "components/app-dropdown";
import AppRadioInput from "components/app-radio-input";
import AppUpdateStatusModal from "components/pages/page-lead-maintence/app-update-status-modal";
import AppAddNoteModal from "components/pages/page-lead-maintence/app-add-note-modal";
import AppTaskDetailsModal from "components/pages/page-lead-maintence/app-task-details-modal";
import scoredCardBar from "assets/images/pages/page-lead-maintence/scored-card.svg";
import smsIcon from "assets/images/pages/page-lead-maintence/sms-icon.svg";
import { onHandleRequestError, onHandleRequestSuccess } from "common/utilities";

const getLabelClassNames = ({ important }) => {
	const classNames = ["customer__label"];

	if (important) classNames.push("customer__label--important");

	return classNames.join(" ");
};

export const getTaskStatusClassNames = ({ status }) => {
	const classNames = ["tasks__status"];

	switch (status) {
		case CONSTANSTS.OPTIONS.LEADS_STATUS[0].value:
			classNames.push("tasks__status--new-lead");
			break;
		case CONSTANSTS.OPTIONS.LEADS_STATUS[1].value:
			classNames.push("tasks__status--follow-up");
			break;
		case CONSTANSTS.OPTIONS.LEADS_STATUS[2].value:
			classNames.push("tasks__status--converted");
			break;
		default:
			classNames.push("tasks__status--low-rate");
			break;
	}

	return classNames.join(" ");
};

const getCustomerStatusClassNames = ({ status }) => {
	const classNames = ["customer__item"];

	switch (status) {
		case CONSTANSTS.OPTIONS.LEADS_STATUS[0].value:
			classNames.push("customer__item--new-lead");
			break;
		case CONSTANSTS.OPTIONS.LEADS_STATUS[1].value:
			classNames.push("customer__item--follow-up");
			break;
		case CONSTANSTS.OPTIONS.LEADS_STATUS[2].value:
			classNames.push("customer__item--converted");
			break;
		default:
			classNames.push("customer__item--low-rate");
			break;
	}

	return classNames.join(" ");
};

const PageCustomer = () => {
	const addNoteModalRef = useRef();
	const updateStatusModalRef = useRef();
	const taskDetailsModalRef = useRef();
	const isMount = useIsMount();
	const navigate = useNavigate();
	const { state } = useLocation();
	const profile = useSelector((state) => state.auth.profile);
	const [addTaskFormVisible, setAddTaskFormVisible] = useState(false);
	const [scoredCard, setScoredCard] = useState(0);
	const [scoredData, setScoredData] = useState(null);
	const [activeTab, setActiveTab] = useState(null);
	const [customer, setCustomer] = useState(null);
	const isAdmin = profile.user_role === CONSTANSTS.ROLE.ADMIN;
	const initialValues = useMemo(() => {
		const values = {
			q0: "",
			q1: "01",
			q2: "",
			q3: "",
			q4: "",
			q5: "",
			q6: "",
		};

		return values;
	}, []);
	const validationSchema = useMemo(
		() =>
			Yup.object().shape({
				q0: Yup.object().required("This field is required"),
				q1: Yup.string().required("This field is required"),
				q2: Yup.object().required("This field is required"),
				q3: Yup.object().required("This field is required"),
				q4: Yup.object().when("q3", {
					is: (opt) => !opt || opt.value === "01",
					then: Yup.object().required("This field is required"),
				}),
				q5: Yup.string().required("This field is required"),
				q6: Yup.string().required("This field is required"),
			}),
		[]
	);
	const formik = useFormik({
		enableReinitialize: true,
		initialValues,
		validationSchema,
		onSubmit: (values) => {
			onHandleConfirmCreate(values);
		},
	});

	const form = useMemo(() => {
		const fields = [
			{
				name: "q0",
				label: "Status",
				placeholder: "Please Select",
				type: "select",
				options: CONSTANSTS.OPTIONS.LEADS_STATUS,
				disabled: false,
			},
			{
				name: "q1",
				label: "Should we do cross sell for customer?",
				placeholder: "",
				type: "radio",
				options: CONSTANSTS.OPTIONS.YES_NO,
				disabled: false,
			},
			{
				name: "q2",
				label: "How is the customer’s behaving?",
				placeholder: "Please Select",
				options: CONSTANSTS.OPTIONS.CUSTOMER_RESPONSE,
				disabled: false,
				type: "select",
			},
			{
				name: "q3",
				label: "Is the customer interested with other product?",
				placeholder: "Please Select",
				options: CONSTANSTS.OPTIONS.YES_NO,
				disabled: false,
				type: "select",
			},
			{
				name: "q4",
				label: "Which product that drives customer interest?",
				placeholder: formik.values.q3.value === "02" ? "-" : "Please Select",
				options: CONSTANSTS.OPTIONS.PRODUCTS,
				disabled: formik.values.q3.value === "02",
				type: "select",
			},
			{
				name: "q5",
				label: "Customer preferred time to talk",
				placeholder: "",
				disabled: false,
				type: "text",
			},
			{
				name: "q6",
				label: "Comments",
				placeholder: "",
				disabled: false,
				type: "textarea",
			},
		];

		return fields;
	}, [formik]);

	const onHandleToggleTab = (tab) => () => {
		if (activeTab !== tab) setActiveTab(tab);
	};

	const onNavigateGoBack = useCallback(() => {
		navigate(pathnames.leadMaintence);
	}, [navigate]);

	const getLeadDetails = useCallback(async () => {
		let response = null;
		let tasks = null;
		let scores = null;

		try {
			response = await api.get.leadDetails(state.cd);
			tasks = await api.get.leadTasks(state.cd);
		} catch (error) {
			onHandleRequestError(error);
		}
		if (response) {
			scores = response.data?.scores;

			if (tasks) {
				response.data.tasks = tasks.data;
			}

			setCustomer(response.data);

			if (!activeTab) setActiveTab("profile");
		}

		if (scores && scores.length) {
			const latestScored = scores[0].score;
			setScoredData(scores[0]);
			setScoredCard(latestScored);
		}
	}, [state, activeTab]);

	useEffect(() => {
		if (isMount) {
			getLeadDetails();
		}
	}, [isMount, state, getLeadDetails]);

	useEffect(() => {
		if (formik.values.q3?.value) {
			if (formik.values.q3.value === "02" && formik.values.q4.value) {
				formik.setFieldValue("q4", "");
			}
		}
	}, [formik]);

	const onHandleToggleMenu = () => {
		const menuItems = document.getElementById("js-toggle-menu");
		menuItems.classList.toggle("customer__dropdown--active");
	};

	const onNavigateEditLead = useCallback(() => {
		navigate(pathnames.newLead, { state: { ...customer.leadResponse, mode: CONSTANSTS.MODE.EDIT } });
	}, [navigate, customer]);

	const onHandleConfirmCreate = useCallback(
		async (values) => {
			let response = null;

			try {
				const payload = {
					created_user: profile.user_name,
					created_by: profile.id,
					lead_cd: state.cd,
					status: values.q0.value,
					cross_sell: values.q1,
					cust_response: values.q2.value,
					product_drive: values.q4.value,
					preferred_talk_time: values.q5,
					comments: values.q6,
				};
				response = await api.post.createLeadTask(payload);
			} catch (error) {
				onHandleRequestError("Failed to update task");
			} finally {
				formik.setSubmitting(false);
			}

			if (response) {
				onHandleRequestSuccess("Task has updated successfully!");
				setAddTaskFormVisible(false);
				getLeadDetails();
				formik.resetForm();
			}
		},
		[profile, formik, state, getLeadDetails]
	);

	const onHandleAdminUpdateTaskStatus = useCallback(
		async (values) => {
			let response = null;

			try {
				const payload = {
					created_by: profile.user_name,
					lead_cd: state.cd,
					status: values.status.value,
					comments: values.note,
				};
				response = await api.post.createLeadTask(payload);
			} catch (error) {
				onHandleRequestError("Failed to update task");
			} finally {
				formik.setSubmitting(false);
			}

			if (response) {
				onHandleRequestSuccess("Task has updated successfully!");
				getLeadDetails();
			}
		},
		[profile, formik, state, getLeadDetails]
	);

	const onHandleConfirmCreateNote = useCallback(
		async (values) => {
			let response = null;

			try {
				const payload = {
					created_user: profile.id,
					created_by: profile.user_name,
					lead_cd: state.cd,
					notes: values.note,
				};
				response = await api.post.createLeadNote(payload);
			} catch (error) {
				onHandleRequestError(error);
			}

			if (response) {
				onHandleRequestSuccess("Note has updated successfully!");
				getLeadDetails();
			}
		},
		[profile, state, getLeadDetails]
	);

	const onHandleConfirmCreateScore = useCallback(async () => {
		let response = null;

		try {
			const payload = {
				created_user: profile.id,
				created_by: profile.user_name,
				lead_cd: state.cd,
				score: scoredCard,
			};
			response = await api.post.createLeadScore(payload);
		} catch (error) {
			onHandleRequestError(error);
		}

		if (response) {
			onHandleRequestSuccess("Score has updated successfully!");
		}
	}, [state, profile, scoredCard]);

	const getPointerStyles = useCallback((value) => {
		const pointer = document.getElementById("data-js-pointer");
		const dot = document.getElementById("data-js-dot");
		const coldColor = "#64C2DB";
		const warmColor = "#7476ED";
		const hotColor = "#E56F8C";
		let rotateDeg = Math.round((value / 100) * 180);

		if (rotateDeg >= 126) {
			dot.style.border = `10px solid ${hotColor}`;
		} else if (rotateDeg >= 54) {
			dot.style.border = `10px solid ${warmColor}`;
		} else {
			dot.style.border = `10px solid ${coldColor}`;
		}

		pointer.style.transform = `rotate(${rotateDeg}deg)`;
	}, []);

	useEffect(() => {
		if (activeTab === "scorecard") {
			getPointerStyles(scoredCard);
		}
	}, [activeTab, scoredCard, getPointerStyles]);

	const onHandleScoreChange = useCallback(
		(event) => {
			let value = parseInt(event.target.value.replace(/[^0-9]/g, ""), 10);
			if (value > 180) value = 180;
			if (!value || value < 0) value = 0;

			setScoredCard(value);
			getPointerStyles(value);
		},
		[getPointerStyles]
	);

	const onHandleToggleAddTaskForm = useCallback(() => {
		setAddTaskFormVisible(!addTaskFormVisible);
	}, [addTaskFormVisible]);

	const onRenderNotes = () => {
		return (
			<div className="customer__form customer__notes">
				<div className="customer__form-header">
					<p className="customer__notes-title">Notes</p>
					<AppButton type="button" label="Add Note" onClick={addNoteModalRef.current?.onHandleShow} />
				</div>
				<ul className="customer__notes-list">
					{customer.notes.map((o, i) => {
						return (
							<li key={i} className="customer__note">
								<p className="customer__text">{o.notes}</p>
								<p className="customer__date">
									<span>{o.created_by}</span> <span>{moment(o.dt_created).format(CONSTANSTS.DATE_FORMAT.DDMMMYYYY_HHMMA)}</span>
								</p>
							</li>
						);
					})}
				</ul>
			</div>
		);
	};

	if (!customer) return null;

	return (
		<div className="page-customer">
			<div className="customer">
				<div className="customer__header">
					<button className="customer__back-button" onClick={onNavigateGoBack}>
						<BsCaretLeftFill />
					</button>
					<h1 className="customer__title">{customer.leadResponse.nm}</h1>
				</div>

				<div className="customer__body">
					<ul className="customer__list">
						<li className={getCustomerStatusClassNames({ status: customer.leadResponse.status })}>
							<p className="customer__label">Status</p>
							<p className="customer__text">{CONSTANSTS.OPTIONS.LEADS_STATUS.filter((o) => o.value === customer.leadResponse.status)?.[0]?.label || "N/A"}</p>
						</li>
						<li className="customer__item">
							<p className="customer__label">Product</p>
							<p className="customer__text">{CONSTANSTS.OPTIONS.PRODUCTS.filter((o) => o.value === customer.leadResponse.product)?.[0]?.label || "N/A"}</p>
						</li>
						<li className="customer__item">
							<p className="customer__label">Source</p>
							<p className="customer__text">{CONSTANSTS.OPTIONS.SOURCES.filter((o) => o.value === customer.leadResponse.source)?.[0]?.label || "N/A"}</p>
						</li>
						<ul className="customer__actions">
							<li className="customer__action-item">
								<IoCall />
							</li>
							<li className="customer__action-item">
								<IoMail />
							</li>
							{profile.user_role === CONSTANSTS.ROLE.ADMIN && (
								<li className="customer__action-item" onClick={onHandleToggleMenu}>
									<BsThreeDotsVertical />
									<ul id="js-toggle-menu" className="customer__dropdown">
										<li className="customer__dropdown-item" onClick={onNavigateEditLead}>
											Edit
										</li>
										<li className="customer__dropdown-item" onClick={updateStatusModalRef.current?.onHandleShow}>
											Update Status
										</li>
									</ul>
								</li>
							)}
						</ul>
					</ul>

					<Nav tabs className="customer__tabs">
						<NavItem>
							<NavLink className={classnames({ active: activeTab === "profile" })} onClick={onHandleToggleTab("profile")}>
								Profile
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink className={classnames({ active: activeTab === "activities" })} onClick={onHandleToggleTab("activities")}>
								Attends Task
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink className={classnames({ active: activeTab === "scorecard" })} onClick={onHandleToggleTab("scorecard")}>
								Scorecard
							</NavLink>
						</NavItem>
					</Nav>
					<TabContent activeTab={activeTab}>
						<TabPane tabId="profile" className="customer__tab">
							<div className="customer__tab-wrapper">
								<div className="customer__form">
									<div className="customer__content-wrapper">
										<p className="customer__form-title">Personal Details</p>
										<div className="customer__content">
											<div className="customer__info">
												<p className="customer__label">Gender</p>
												<p className="customer__text">{CONSTANSTS.OPTIONS.GENDER.filter((o) => o.value === customer.leadResponse.gender)?.[0]?.label || "N/A"}</p>
											</div>
											<div className="customer__info">
												<p className="customer__label">Age</p>
												<p className="customer__text">{customer.leadResponse.age}</p>
											</div>
											<div className="customer__info">
												<p className="customer__label">Race</p>
												<p className="customer__text">{CONSTANSTS.OPTIONS.RACE.filter((o) => o.value === customer.leadResponse.race)?.[0]?.label || "N/A"}</p>
											</div>
											<div className="customer__info">
												<p className="customer__label">Marital Status</p>
												<p className="customer__text">{CONSTANSTS.OPTIONS.MARITAL_STATUS.filter((o) => o.value === customer.leadResponse.marital_status)?.[0]?.label || "N/A"}</p>
											</div>
											<div className="customer__info">
												<p className="customer__label">No. of Dependant</p>
												<p className="customer__text">{customer.leadResponse.dependent_num}</p>
											</div>
										</div>
									</div>
									<div className="customer__content-wrapper">
										<p className="customer__form-title">Contact Info</p>
										<div className="customer__content">
											<div className="customer__info">
												<p className="customer__label">Residential Address</p>
												<p className="customer__text">{`${customer.leadResponse.pri_address_1}, ${customer.leadResponse.pri_address_2}, ${customer.leadResponse.pri_postcode}, ${
													CONSTANSTS.OPTIONS.STATES.filter((o) => o.value === customer.leadResponse.pri_state)?.[0]?.label || ""
												}`}</p>
											</div>
											<div className="customer__info">
												<p className="customer__label">Permanent Address</p>
												<p className="customer__text">Same as Residential Address</p>
											</div>
											<div className="customer__info">
												<p className="customer__label">Email Address</p>
												<p className="customer__text">{customer.leadResponse.email}</p>
											</div>
											<div className="customer__info">
												<p className="customer__label">Contact No.</p>
												<p className="customer__text">{customer.leadResponse.phone}</p>
											</div>
										</div>
									</div>
									<div className="customer__content-wrapper">
										<p className="customer__form-title">Employment Details</p>
										<div className="customer__content">
											<div className="customer__info">
												<p className="customer__label">Employment Type</p>
												<p className="customer__text">{CONSTANSTS.OPTIONS.EMPLOYMENT_TYPE.filter((o) => o.value === customer.leadResponse.employment_type)?.[0]?.label || "N/A"}</p>
											</div>
											<div className="customer__info">
												<p className="customer__label">Job Title</p>
												<p className="customer__text">{customer.leadResponse.occupation}</p>
											</div>
											<div className="customer__info">
												<p className="customer__label">Monthly Gross / Net Income</p>
												<p className="customer__text">RM {customer.leadResponse.gross_income.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</p>
											</div>
											<div className="customer__info">
												<p className="customer__label">Office Address</p>
												<p className="customer__text">{`${customer.leadResponse.com_address_1}, ${customer.leadResponse.com_address_2}, ${customer.leadResponse.com_postcode}, ${
													CONSTANSTS.OPTIONS.STATES.filter((o) => o.value === customer.leadResponse.com_state)?.[0]?.label || ""
												}`}</p>
											</div>
										</div>
									</div>
								</div>
								{onRenderNotes()}
							</div>
						</TabPane>
						<TabPane tabId="activities" className="customer__tab">
							<div className="customer__tab-wrapper">
								{!addTaskFormVisible && (
									<div className="customer__tasks-container">
										{!isAdmin && (
											<div className="customer__task-header">
												<AppButton type="button" label="Add Task" onClick={onHandleToggleAddTaskForm} />
											</div>
										)}
										<ul className="tasks">
											{customer.tasks.map((o, i) => {
												return (
													<li key={i} className="tasks__item">
														<p className="tasks__date">{moment(o.dt_created).format(CONSTANSTS.DATE_FORMAT.DDMMMYYYY_HHMMA)}</p>
														<div className="tasks__task">
															<div className="tasks__column">
																<p className={getTaskStatusClassNames({ status: o.status })}>{CONSTANSTS.OPTIONS.LEADS_STATUS.filter((f) => f.value === o.status)?.[0]?.label}</p>
																<p className="tasks__value"></p>
															</div>
															<div className="tasks__column">
																<p className="tasks__label">Cross Sell</p>
																<p className="tasks__value">
																	{o.cross_sell === "01" ? <IoCheckmarkCircleOutline className="tasks__icon tasks__icon--success" /> : <IoCloseCircleOutline className="tasks__icon tasks__icon--error" />}
																</p>
															</div>

															<div className="tasks__column">
																<p className="tasks__label">Behaving</p>
																<p className="tasks__value">{CONSTANSTS.OPTIONS.CUSTOMER_RESPONSE.filter((f) => f.value === o.cust_response)?.[0]?.label}</p>
															</div>
															<div className="tasks__column">
																<p className="tasks__label">Drive Interest</p>
																<p className="tasks__value">{CONSTANSTS.OPTIONS.PRODUCTS.filter((f) => f.value === o.product_drive)?.[0]?.label || "-"}</p>
															</div>
															<div className="tasks__column">
																<p className="tasks__label">Preferred time</p>
																<p className="tasks__value">{o.preferred_talk_time}</p>
															</div>
															<div className="tasks__column">
																<p className="tasks__label">Attending agent</p>
																<p className="tasks__value">{o.created_user}</p>
															</div>
															<div className="tasks__column tasks__column--action" onClick={() => taskDetailsModalRef.current?.onHandleShow(o)}>
																<BsThreeDotsVertical />
															</div>
														</div>
													</li>
												);
											})}
										</ul>
									</div>
								)}
								{addTaskFormVisible && (
									<div className="customer__form">
										<form autoComplete="off" onSubmit={formik.handleSubmit}>
											<div className="customer__attends-task">
												{form.map(({ label, important, ...res }, i) => {
													const value = formik.values[res.name];
													const error = formik.errors[res.name];
													const touched = formik.touched[res.name];
													const isRadioField = res.type === "radio";
													const isDropdownField = res.type === "select";

													if (isDropdownField) {
														return (
															<div key={i} className="customer__field">
																<p className="customer__label">{label}</p>
																<AppDropdown {...res} value={value} error={error} touched={touched} data={res.options} onChange={formik.setFieldValue} />
															</div>
														);
													}

													if (isRadioField) {
														return (
															<div key={i} className="customer__field">
																<p className="customer__label">{label}</p>
																<AppRadioInput {...res} value={value} onChange={formik.setFieldValue} />
															</div>
														);
													}

													return (
														<div key={i} className="customer__field">
															<p className={getLabelClassNames({ important })}>{label}</p>
															<AppInput {...res} value={value} error={error} touched={touched} onChange={formik.handleChange} />
														</div>
													);
												})}
											</div>
											<div className="customer__button-container">
												<div className="customer__button-wrapper">
													<AppButton label="Cancel" type="button" cancel onClick={onHandleToggleAddTaskForm} />
													<AppButton label="Submit" type="submit" loading={formik.isSubmitting} />
												</div>
											</div>
										</form>
									</div>
								)}
								{onRenderNotes()}
							</div>
						</TabPane>
						<TabPane tabId="scorecard" className="customer__tab">
							<div className="customer__tab-wrapper">
								<div className="customer__form customer__chart">
									<div className="scored-card">
										<div id="data-js-pointer" className="scored-card__pointer">
											<div className="scored-card__item">
												<div id="data-js-dot" className="scored-card__dot" />
											</div>
										</div>
										<img className="scored-card__bar" src={scoredCardBar} alt="" />
										<div className="scored-card__profit-value">
											<input type="text" name="score" className="scored-card__score" disabled={!isAdmin} value={scoredCard} onChange={onHandleScoreChange} />
										</div>
									</div>
									<div className="scored-card__footer">
										<div className="scored-card__content">
											<p className="scored-card__title">
												Lead score is <span className={scoredCard >= 70 ? "hot" : scoredCard >= 30 ? "warm" : "cold"}>{scoredCard >= 70 ? "Hot" : scoredCard >= 30 ? "Warm" : "Cold"}</span>
											</p>
										</div>
										{isAdmin && <AppButton label="Save Score" type="button" onClick={onHandleConfirmCreateScore} />}
									</div>
								</div>
								{onRenderNotes()}
							</div>
						</TabPane>
					</TabContent>
				</div>
			</div>
			<AppUpdateStatusModal ref={updateStatusModalRef} onHandleConfirm={onHandleAdminUpdateTaskStatus} />
			<AppAddNoteModal ref={addNoteModalRef} onHandleConfirm={onHandleConfirmCreateNote} />
			<AppTaskDetailsModal ref={taskDetailsModalRef} />
		</div>
	);
};

export default PageCustomer;
