import React, { useMemo, useState, useRef, useCallback, useEffect, Fragment } from "react";
import moment from "moment";
import { debounce } from "lodash";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { BsCaretLeftFill } from "react-icons/bs";

import api from "services/api";
import pathnames from "routes/pathnames";
import CONSTANSTS from "common/constansts";
import useIsMount from "hooks/use-is-mount";
import AppButton from "components/app-button";
import AppEmptyData from "components/app-empty-data";
import AppTable, { TableStatus, TableEditButton } from "components/app-table";
import { onHandleRequestError } from "common/utilities";

const PageMatrixConfiguration = (props) => {
	const navigate = useNavigate();
	const { state } = useLocation();
	const profile = useSelector((state) => state.auth.profile);

	const onNavigateGoBack = useCallback(() => {
		window.open("https://atf-lead-management.lengzai.asia:8043/kie-wb/kie-wb.jsp");
	}, []);

	return (
		<div className="page-matrix-configure">
			<div className="matrix-configure">
				<div className="matrix-configure__header">
					<div className="matrix-configure__header-wrapper">
						<button className="matrix-configure__back-button" type="button" onClick={onNavigateGoBack}>
							<BsCaretLeftFill />
						</button>
						<h1 className="matrix-configure__title">{state.cd + "-" + state.nm}</h1>
					</div>
				</div>
				<div className="matrix-configure__container">
					<iframe src="https://atf-lead-management.lengzai.asia:8043/kie-wb/kie-wb.jsp" />
				</div>
			</div>
		</div>
	);
};

export default PageMatrixConfiguration;
