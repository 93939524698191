import React, { Fragment, memo, useRef, useCallback } from "react";
import { compose } from "redux";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import CONSTANSTS from "common/constansts";
import pathnames from "routes/pathnames";
import AppLogoutModal from "components/app-logout-modal";
import AppNotificationModal from "components/app-notification-modal";
import logo from "assets/images/logo.svg";
import bellIcon from "assets/images/components/app-nav-header/bell-icon.svg";
import logoutIcon from "assets/images/components/app-nav-header/logout-icon.svg";
import profileImage from "assets/images/components/app-nav-header/profile.jpeg";

const getNavItemClassNames = ({ navActive }) => {
	const classNames = ["nav-header__nav-item"];

	if (navActive) classNames.push("nav-header__nav-item--active");

	return classNames.join(" ");
};

const navItems = [
	{
		label: "DASHBOARD",
		to: pathnames.dashboard,
	},
	{
		label: "LEAD MAINTENANCE",
		to: pathnames.leadMaintence,
		relations: [pathnames.customer, pathnames.newLead],
		restrict: CONSTANSTS.ROLE.ADMIN,
	},
	{
		label: "TASK LIST",
		to: pathnames.leadMaintence,
		restrict: CONSTANSTS.ROLE.AGENT,
	},
	{
		label: "DECISION MATRIX MAINTENANCE",
		to: pathnames.decisionMatrixMaintenanace,
		relations: [pathnames.matrixConfiguration],
		restrict: CONSTANSTS.ROLE.ADMIN,
	},
];

const AppNavHeader = ({ title, ...props }) => {
	const notificationRef = useRef();
	const logoutRef = useRef();
	const location = useLocation();
	const profile = useSelector((state) => state.auth.profile);
	const profileImageStyle = { backgroundImage: `url(${profileImage})` };

	//prettier-ignore
	const onHandleVisibleLogoutModal = useCallback(() => {
		logoutRef.current?.onHandleShow();
	}, []);

	return (
		<Fragment>
			<nav className="app-nav-header">
				<div className="nav-header">
					<ul className="nav-header__list">
						<div className="nav-header__logo">
							<img src={logo} alt="" />
						</div>
						{navItems.map((o, i) => {
							const role = profile?.user_role;
							const navActive = location.pathname === o.to || o.relations?.includes(location.pathname);

							if (!o.restrict || role === o.restrict) {
								return (
									<li key={i} className={getNavItemClassNames({ navActive })}>
										<Link to={o.to}>{o.label}</Link>
									</li>
								);
							}

							return null;
						})}
					</ul>

					<ul className="nav-header__list">
						<li className="nav-header__item">
							<div className="nav-header__profile" style={profileImageStyle} />
						</li>
						<li className="nav-header__item">
							<div className="nav-header__actions">
								<button className="nav-header__notification">
									{props.notificationAlert && <div className="nav-header__alert" />}
									<img src={bellIcon} alt="notification" />
								</button>
								<button onClick={onHandleVisibleLogoutModal}>
									<img src={logoutIcon} alt="logout" />
								</button>
							</div>
						</li>
					</ul>
				</div>
			</nav>
			<AppNotificationModal ref={notificationRef} />
			<AppLogoutModal ref={logoutRef} />
		</Fragment>
	);
};

export default compose(memo)(AppNavHeader);
