import React, { useMemo, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { IoIosPerson, IoIosLock } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";

import api from "services/api";
import pathnames from "routes/pathnames";
import CONSTANSTS from "common/constansts";
import useIsMount from "hooks/use-is-mount";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import { updateProfile } from "redux/slices/auth-slice";
import { trimStrings } from "common/utilities";

const initialValues = {
	user_cd: process.env.REACT_APP_LOGIN_EMAIL,
	password: process.env.REACT_APP_LOGIN_PASSWORD,
};

const PageSignIn = () => {
	const isMount = useIsMount();
	const { t } = useTranslation("signIn");
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { profile } = useSelector((state) => state.auth);
	//prettier-ignore
	const validationSchema = useMemo(() => Yup.object().shape({
		user_cd: Yup.string().required(t("common:required")),
		password: Yup.string().required(t("common:required")),
	}), [t]);
	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: () => {
			onHandleSignIn();
		},
	});

	useEffect(() => {
		if (isMount && profile) {
			navigate(pathnames.dashboard);
		}
	}, [isMount, navigate, profile]);

	const onHandleSignIn = async () => {
		let response = null;

		try {
			const payload = trimStrings(formik.values);
			response = await api.post.signIn(payload);
		} catch (error) {
			formik.setFieldError("email", t("error"));
			formik.setFieldError("password", t("error"));
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			let profile = null;
			const token = response.data.session_id;

			profile = response.data;

			localStorage.setItem(CONSTANSTS.LOCAL_STORAGE.STORAGE_TOKEN, token);

			if (profile) dispatch(updateProfile(profile));

			navigate(pathnames.dashboard);
		}
	};

	return (
		<div className="app-page page-sign-in">
			<div className="sign-in">
				<div className="sign-in__container">
					<div className="sign-in__wrapper">
						<h1 className="sign-in__title">
							Welcome back <br />
							to <span>LMS</span>
						</h1>

						<form autoComplete="off" onSubmit={formik.handleSubmit}>
							<label className="sign-in__label">Username</label>
							<AppInput type="text" name="user_cd" placeholder="" icon={<IoIosPerson />} error={formik.errors.user_cd} touched={formik.touched.user_cd} onChange={formik.handleChange} />
							<br />
							<label className="sign-in__label">Password</label>
							<AppInput type="password" name="password" placeholder="" icon={<IoIosLock />} error={formik.errors.password} touched={formik.touched.password} onChange={formik.handleChange} />

							<div className="sign-in__button-container">
								<Link className="sign-in__link" to={pathnames.signIn}>
									{t("forgotPassword")}
								</Link>

								<AppButton type="submit" label={t("cta")} loading={formik.isSubmitting} />
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PageSignIn;
