import i18n from "locales/i18n";

const CONSTANSTS = {
	IS_DEVELOPMENT_MODE: !process.env.NODE_ENV || process.env.NODE_ENV === "development",
	ENDPOINT_PATH: {
		SIGN_IN: "/login",
		GET_LEADS: "/lead/getLeadListingFilter",
		GET_LEAD_DETAILS: "/lead/getLeadDetail/",
		GET_LEAD_TASKS: "/lead/getLeadTaskByLeadCode/",
		CREATE_LEAD_TASK: "/lead/addLeadTask",
		CREATE_LEAD_NOTE: "/lead/addLeadNote",
		CREATE_LEAD_SCORE: "/lead/addLeadScore",
		CREATE_LEAD_INFO: "/lead/addLeadInfo",
		UPDATE_LEAD_INFO: "/lead/updateLeadInfo",
		GET_MATRIXS: "/rule/getRuleListing",
	},
	DATE_FORMAT: {
		HHMM: "HH:mma",
		YYYYMMDD: "YYYY-MM-DD",
		DDMMYYYY: "DD/MM/yyyy",
		DDMMMYYYY: "DD MMM yyyy",
		ddMMYYYY: "dd/MM/yyyy",
		YYYYMMDDHH_MMA: "YYYY-MM-DD, HH:mmA",
		DDMMMYYYY_HHMMA: "DD-MMM-YYYY, HH:mmA",
		DOB_FORMAT: "YYYY-MM-DD HH:mm:ss",
	},
	REGEX: {
		PASSWORD: /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
	},
	LOCAL_STORAGE: {
		STORAGE_TOKEN: "@menara-storage-token",
		USER_LANGUAGE: "@menara-user-language",
	},
	ROLE: {
		ADMIN: "01",
		AGENT: "02",
	},
	MODE: {
		CREATE: "create",
		EDIT: "edit",
	},
	STATUS: {
		ACTIVE: "active",
		USED: "used",
		EXPIRED: "expired",
		INACTIVE: "inactive",
		PENDING_VERIFICATION: "pendingVerification",
	},
	COUNTRIES: [{ label: "Malaysia", value: "malaysia" }],
	OPTIONS: {
		YES_NO: [
			{ label: "Yes", value: "01" },
			{ label: "No", value: "02" },
		],
		GENDER: [
			{ label: "Male", value: "01" },
			{ label: "Female", value: "02" },
		],
		RACE: [
			{ label: "Malay", value: "01" },
			{ label: "Chinese", value: "02" },
			{ label: "Indian", value: "03" },
			{ label: "Others", value: "04" },
		],
		MARITAL_STATUS: [
			{ label: "Single", value: "01" },
			{ label: "Married", value: "02" },
			{ label: "Widowed", value: "03" },
		],
		EMPLOYMENT_TYPE: [
			{ label: "Self-Employed", value: "01" },
			{ label: "Employed", value: "02" },
			{ label: "Unemployed", value: "03" },
		],
		COMPANY_SIZE: [
			{ label: "1 - 50", value: "01" },
			{ label: "51 - 100", value: "02" },
			{ label: "101 - 500", value: "03" },
			{ label: "501 and Above", value: "04" },
		],
		NATURAL_OF_BUSINESS: [
			{ label: "Agriculture & Forestry/ Wildlife", value: "01" },
			{ label: "Business & Information", value: "02" },
			{ label: "Construction/ Utilities/ Contracting", value: "03" },
			{ label: "Education", value: "04" },
			{ label: "Finance & Insurance", value: "05" },
			{ label: "Food & Hospitality", value: "06" },
			{ label: "Gaming", value: "07" },
			{ label: "Health Services", value: "08" },
			{ label: "Motor Vehicle", value: "09" },
			{ label: "Natural Resources/ Environmental", value: "10" },
			{ label: "Other", value: "11" },
			{ label: "Personal Services", value: "12" },
			{ label: "Real Estate & Housing", value: "13" },
			{ label: "Safety/ Security & Legal", value: "14" },
			{ label: "Transportation", value: "15" },
		],
		PRODUCTS: [
			{ label: "Personal Loan", value: "01" },
			{ label: "Credit Card", value: "02" },
			{ label: "Investment Banking", value: "03" },
			{ label: "Credit Card Insurance", value: "04" },
		],
		SOURCES: [
			{ label: "Corporate Site", value: "01" },
			{ label: "Internet Banking", value: "02" },
			{ label: "Facebook", value: "03" },
			{ label: "Instagram", value: "04" },
			{ label: "Manual", value: "05" },
			{ label: "Others", value: "06" },
		],
		CUSTOMER_RESPONSE: [
			{ label: "Hot", value: "01" },
			{ label: "Warm", value: "02" },
			{ label: "Cold", value: "03" },
		],
		STATES: [
			{ label: "Johor", value: "01" },
			{ label: "Kedah", value: "02" },
			{ label: "Kelantan", value: "03" },
			{ label: "Melaka", value: "04" },
			{ label: "Negeri Sembilan", value: "05" },
			{ label: "Pahang", value: "06" },
			{ label: "Pulau Pinang", value: "07" },
			{ label: "Perak", value: "08" },
			{ label: "Perlis", value: "09" },
			{ label: "Selangor", value: "10" },
			{ label: "Terengganu", value: "11" },
			{ label: "Sabah", value: "12" },
			{ label: "Sarawak", value: "13" },
			{ label: "Wilayah Persekutuan Kuala Lumpur", value: "14" },
			{ label: "Wilayah Persekutuan Labuan", value: "15" },
			{ label: "Wilayah Persekutuan Putrajaya", value: "16" },
		],
		USER_STATUS: [
			{ label: "Active", value: "01" },
			{ label: "Suspend", value: "02" },
			{ label: "Deleted", value: "03" },
			{ label: "Locked", value: "04" },
			{ label: "Pending Confirmation", value: "05" },
		],
		LEADS_STATUS: [
			{ label: "Assessment", value: "01" },
			{ label: "Follow-up", value: "02" },
			{ label: "Converted", value: "03" },
			{ label: "Keep In View", value: "04" },
		],
		LEAD_TYPE: [
			{ label: "Individual", value: "01" },
			{ label: "Company", value: "02" },
		],
		RULE_TYPE: [{ label: "Scorecard", value: "01" }],
	},
	FILTER_BY: {
		STATUS: [],
		VOUCHER_STATUS: [],
		CUSTOMER_STATUS: [],
		STATES: [],
	},
};

const initializeOptions = () => {};

initializeOptions();
i18n.on("languageChanged", initializeOptions);

export default CONSTANSTS;
