import PageSignIn from "pages/page-sign-in";
import PageNotFound from "pages/page-not-found";

import PageDashboard from "pages/dashboard/page-dashboard";
import PageLeadMaintence from "pages/lead-maintence/page-lead-maintence";
import PageNewLead from "pages/lead-maintence/page-new-lead";
import PageCustomer from "pages/lead-maintence/page-customer";
import PageMatrixDecisionMaintence from "pages/matrix-decision-maintence/matrix-decision-maintence";
import PageMatrixConfiguration from "pages/matrix-decision-maintence/matrix-configuration";

import pathnames from "routes/pathnames";

const routes = [
	{
		path: pathnames.signIn,
		component: PageSignIn,
		query: null,
		title: "Sign In",
		authenticate: false,
		restrict: null,
	},
	{
		path: pathnames.dashboard,
		component: PageDashboard,
		query: null,
		title: "Dashboard",
		authenticate: true,
		restrict: null,
	},
	{
		path: pathnames.leadMaintence,
		component: PageLeadMaintence,
		query: null,
		title: "Lead maintence",
		authenticate: true,
		restrict: null,
	},
	{
		path: pathnames.newLead,
		component: PageNewLead,
		query: null,
		title: "New Lead",
		authenticate: true,
		restrict: null,
	},
	{
		path: pathnames.customer,
		component: PageCustomer,
		query: null,
		title: "Customer",
		authenticate: true,
		restrict: null,
	},
	{
		path: pathnames.decisionMatrixMaintenanace,
		component: PageMatrixDecisionMaintence,
		query: null,
		title: "Matrix Decision Maintence",
		authenticate: true,
		restrict: null,
	},
	{
		path: pathnames.matrixConfiguration,
		component: PageMatrixConfiguration,
		query: null,
		title: "Matrix Decision Maintence",
		authenticate: true,
		restrict: null,
	},
	{
		path: "*",
		component: PageNotFound,
		query: null,
		title: "404",
		authenticate: false,
		restrict: null,
	},
];

export default routes;
