import React, { useMemo, useState, useRef, useCallback, useEffect, Fragment } from "react";
import moment from "moment";
import { debounce } from "lodash";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";

import api from "services/api";
import pathnames from "routes/pathnames";
import CONSTANSTS from "common/constansts";
import useIsMount from "hooks/use-is-mount";
import AppButton from "components/app-button";
import AppEmptyData from "components/app-empty-data";
import AppTable, { TableStatus, TableEditButton } from "components/app-table";
import { onHandleRequestError } from "common/utilities";

const PageMatrixDecisionMaintence = (props) => {
	const navigate = useNavigate();
	const isMount = useIsMount();
	const profile = useSelector((state) => state.auth.profile);
	const [customers, setCustomers] = useState(null);
	const [totalPage, setTotalPage] = useState(1);
	const isAdmin = profile.user_role === CONSTANSTS.ROLE.ADMIN;
	const filterBy = useRef({ length: 10, page: 0, value: "", sortBy: "", status: !isAdmin ? "02" : "" });
	const isEmpty = !customers?.length || !customers;
	const isEmptySearch = !customers?.length && filterBy.current.value;

	const getDecisionMatrix = useCallback(async () => {
		let response = null;

		try {
			const payload = {
				result_offset: filterBy.current.page === 0 ? 0 : filterBy.current.page * 10,
				result_size: filterBy.current.length,
				filter_criteria: filterBy.current.value,
				sort_criteria: filterBy.current.sortBy,
				status_filter: filterBy.current.status,
			};

			response = await api.get.matrixs(payload);
		} catch (error) {}

		if (response) {
			setCustomers(response.data);
		}
	}, []);

	useEffect(() => {
		if (isMount) {
			getDecisionMatrix();
		}
	}, [isMount, getDecisionMatrix]);

	useEffect(() => {
		return () => props.onHandleCancelRequest();
	}, [props]);

	//prettier-ignore
	const onHandleSortTable = useCallback((order, key) => {
			filterBy.current.page = 0;
			filterBy.current.sortBy = `${key} ${order.toUpperCase()} `;
			getDecisionMatrix();
		}, [getDecisionMatrix]
	);

	//prettier-ignore
	const columns = useMemo(() => [
			{
				Header: "Table Code",
				accessor: "cd",
			},
			{
				Header: "Table Name",
				accessor: "nm",
			},
			{
				Header: "Table Type",
				accessor: "type",
				Cell: ({ cell: { value } }) => CONSTANSTS.OPTIONS.RULE_TYPE.filter(o => o.value === value)?.[0]?.label || "N/A",
			},
			{
				Header: "Triggering Status",
				accessor: "trigger_status",
	            Cell: ({ cell: { value } }) => <TableStatus status={value}/>,				
			},
			{
				Header: "Created Date",
				accessor: "dt_created",
                Cell: ({ cell: { value } }) => moment(value).format(CONSTANSTS.DATE_FORMAT.DDMMMYYYY_HHMMA),
			},
			{
				Header: "Update Date",
				accessor: "dt_modified",
				Cell: ({ cell: { value } }) => moment(value).format(CONSTANSTS.DATE_FORMAT.DDMMMYYYY_HHMMA),
				
			},
			{
				Header: "Updated By",
				accessor: "modified_by_name",
				
			},
			{
				Header: "",
				accessor: "t-action",
				Cell: ({ row }) => {
					const decision = row.original;

					return (					
						<TableEditButton
							onClick={() => {
								window.open("https://atf-lead-management.lengzai.asia:8043/kie-wb/kie-wb.jsp");
							}}
						/>
					);
				},
			},
		],
		 [isAdmin, getDecisionMatrix, onHandleSortTable, navigate]
	);

	const onChangePagination = (event) => {
		filterBy.current.page = +event.target.value;
		getDecisionMatrix();
	};

	const onSearchCustomer = (event) => {
		const value = event.target.value;
		// filterBy.current = { ...filterBy.current, length: 10, page: 0, value: value };
		// getDecisionMatrix();
	};

	const onHandleSearchCustomer = debounce(onSearchCustomer, 2000);

	return (
		<div className="page-lead-maintence">
			<div className="lead-maintence">
				<h1 className="lead-maintence__title">Decision Matrix Maintenance</h1>

				<div className="lead-maintence__header">
					<p className="lead-maintence__description"></p>
					<div className="lead-maintence__search">
						<input size="30" name="search" placeholder="Search by Table Code or Table Name" onChange={onHandleSearchCustomer} />
						<IoSearchOutline />
						<AppButton type="button" label="Export" outline onClick={() => {}} />
					</div>
				</div>

				<div className="lead-maintence__body">
					{(isEmpty || isEmptySearch) && <AppEmptyData data={customers} search={isEmptySearch} />}
					{!isEmpty && customers && <AppTable columns={columns} data={customers} total={totalPage} page={filterBy.current.page} />}
				</div>
			</div>
		</div>
	);
};

export default PageMatrixDecisionMaintence;
