import React, { memo, useImperativeHandle, forwardRef, useState, useCallback } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import CONSTANSTS from "common/constansts";

export const getTaskStatusClassNames = ({ status }) => {
	const classNames = ["task-details-modal__status", "task-details-modal__text"];

	switch (status) {
		case CONSTANSTS.OPTIONS.LEADS_STATUS[0].value:
			classNames.push("task-details-modal__status--new-lead");
			break;
		case CONSTANSTS.OPTIONS.LEADS_STATUS[1].value:
			classNames.push("task-details-modal__status--follow-up");
			break;
		case CONSTANSTS.OPTIONS.LEADS_STATUS[2].value:
			classNames.push("task-details-modal__status--converted");
			break;
		default:
			classNames.push("task-details-modal__status--low-rate");
			break;
	}

	return classNames.join(" ");
};

const AppTaskDetailsModal = forwardRef((props, ref) => {
	const [visible, setVisible] = useState(false);
	const [data, setData] = useState({});

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	const onHandleShow = useCallback((o) => {
		setVisible(true);
		setData(o);
	}, []);

	useImperativeHandle(ref, () => ({
		onHandleDismiss: onHandleDismiss,
		onHandleShow: onHandleShow,
	}));

	console.log(data);

	return (
		<Modal className="app-task-details-modal" contentClassName="task-details-modal" centered size="lg" isOpen={visible} toggle={onHandleDismiss}>
			<ModalHeader className="task-details-modal__header" toggle={onHandleDismiss}></ModalHeader>
			<ModalBody className="task-details-modal__body">
				<ul className="task-details-modal__list">
					<li>
						<p className="task-details-modal__label">Attending Agent</p>
						<p className="task-details-modal__text">{data.created_user}</p>
					</li>
					<li>
						<p className={"task-details-modal__label"}>Status</p>
						<p className={getTaskStatusClassNames({ status: data.status })}>{CONSTANSTS.OPTIONS.LEADS_STATUS.filter((f) => f.value === data.status)?.[0]?.label}</p>
					</li>
					<li>
						<p className="task-details-modal__label">Should we do cross sell for customer?</p>
						<p className="task-details-modal__text">{data.cross_sell === "01" ? "Yes" : "No"}</p>
					</li>
					<li>
						<p className="task-details-modal__label">How is the customer’s behaving?</p>
						<p className="task-details-modal__text">{CONSTANSTS.OPTIONS.CUSTOMER_RESPONSE.filter((f) => f.value === data.cust_response)?.[0]?.label}</p>
					</li>
					<li>
						<p className="task-details-modal__label">Is the customer interested with other product?</p>
						<p className="task-details-modal__text">{data.product_drive ? "Yes" : "No"}</p>
					</li>
					<li>
						<p className="task-details-modal__label">Which product that drives customer interest?</p>
						<p className="task-details-modal__text">{CONSTANSTS.OPTIONS.PRODUCTS.filter((f) => f.value === data.product_drive)?.[0]?.label || "-"}</p>
					</li>
					<li>
						<p className="task-details-modal__label">Customer preferred time to talk</p>
						<p className="task-details-modal__text">{data.preferred_talk_time}</p>
					</li>
					<li>
						<p className="task-details-modal__label">Comments</p>
						<p className="task-details-modal__text">{data.comments}</p>
					</li>
				</ul>
			</ModalBody>
		</Modal>
	);
});

export default memo(AppTaskDetailsModal);
