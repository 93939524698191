import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import "locales/i18n";
import PageApp from "pages/page-app";
import store, { persistor } from "redux/store";
import "styles/index.scss";

// import { registerServiceWorker } from "./serviceWorker";

const App = () => {
	return (
		<React.StrictMode>
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<PageApp />
				</PersistGate>
			</Provider>
		</React.StrictMode>
	);
};

ReactDOM.render(<App />, document.getElementById("root"));

// registerServiceWorker();
