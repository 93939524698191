import React, { useCallback, memo } from "react";
import { Input as Field } from "reactstrap";

const AppLengthOfBusinessInput = ({ note, touched, error, icon, type, ...props }) => {
	const disabled = props.disabled;
	const invalid = touched && error;

	//prettier-ignore
	const onHandleBlur = useCallback((event) => {
		event.currentTarget.value = event.currentTarget.value.trim();
		props.onChange(event);
	}, [props]);

	return (
		<div className="app-input app-length-of-business-input">
			<div className={getWrapperClassNames({ invalid, disabled })}>
				<Field {...props} className="app-length-of-business-input__input" onBlur={onHandleBlur} autoComplete="off" />
				<p className="app-length-of-business-input__label">Year(s)</p>
			</div>

			{invalid && <p className="app-length-of-business-input__text app-length-of-business-input__text--error">{error}</p>}

			{note && <p className="app-length-of-business-input__text">{note}</p>}
		</div>
	);
};

const getWrapperClassNames = ({ invalid, disabled }) => {
	const classNames = ["app-length-of-business-input__container"];

	if (disabled) classNames.push("app-length-of-business-input__container--disabled");

	if (invalid) classNames.push("app-length-of-business-input__container--error");

	return classNames.join(" ");
};

export default memo(AppLengthOfBusinessInput);
