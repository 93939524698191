import React, { memo, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { IoChevronBackOutline, IoChevronForwardOutline } from "react-icons/io5";

const AppPagination = ({ page, total, onChangePagination }) => {
	const { t } = useTranslation("components");
	const selectRef = useRef();

	useEffect(() => {
		let observerRef = selectRef.current;

		observerRef.addEventListener("change", onChangePagination);

		return () => observerRef.removeEventListener("change", onChangePagination);
	}, [onChangePagination, selectRef]);

	const onHandleChangePagination = (event) => {
		const type = event.target.dataset.type;

		switch (type) {
			case "arrow-back":
				if (selectRef.current.value >= 1) {
					selectRef.current.value--;
					selectRef.current.dispatchEvent(new Event("change"));
				}
				break;
			case "arrow-forward":
				if (+selectRef.current.value + 1 < total) {
					selectRef.current.value++;
					selectRef.current.dispatchEvent(new Event("change"));
				}
				break;
			default:
				break;
		}
	};

	return (
		<div className="app-pagination">
			<p className="app-pagination__description">{t("pagination.showing")}</p>

			<div className="app-pagination__controller">
				{t("pagination.page")}
				<select ref={selectRef} data-type="select" value={page} readOnly>
					{[...Array(Math.ceil(total)).keys()]
						.map((i) => i + 1)
						.map((pageSize) => {
							const size = pageSize === 0 ? 0 : pageSize - 1;
							return (
								<option key={pageSize} value={size}>
									{pageSize}
								</option>
							);
						})}
				</select>
				{t("pagination.of", { page: total })}
				<span className="app-pagination__action">
					<button className="app-pagination__action-cta" data-type="arrow-back" onClick={onHandleChangePagination}>
						<IoChevronBackOutline className="app-pagination__arrow" />
					</button>
					<button className="app-pagination__action-cta" data-type="arrow-forward" onClick={onHandleChangePagination}>
						<IoChevronForwardOutline className="app-pagination__arrow" />
					</button>
				</span>
			</div>
		</div>
	);
};

export default memo(AppPagination);
