import React, { memo } from "react";
import { Input as Field } from "reactstrap";

const AppCheckbox = ({ note, touched, error, type, ...props }) => {
	const disabled = props.disabled;
	const invalid = touched && error;

	console.log(props);
	return (
		<div className="app-checkbox">
			<div className={getWrapperClassNames({ invalid, disabled })}>
				<Field {...props} className="app-checkbox__input" type={type} autoComplete="off" />
			</div>

			{invalid && <p className="app-checkbox__text app-checkbox__text--error">{error}</p>}

			{note && <p className="app-checkbox__text">{note}</p>}
		</div>
	);
};

const getWrapperClassNames = ({ invalid, disabled }) => {
	const classNames = ["app-checkbox__container"];

	if (disabled) classNames.push("app-checkbox__container--disabled");

	if (invalid) classNames.push("app-checkbox__container--error");

	return classNames.join(" ");
};

export default memo(AppCheckbox);
