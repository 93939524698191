const bm = {
	common: {
		required: "Bidang ini diperlukan . Sila semaknya sepadan dengan medan di atas.",
		loading: "Pemuatan",
		search: {
			loading: "Searching",
			empty: "Sorry, we couldn't find any matches.",
			error: "Sorry, there is no available data to display.",
		},
		errors: {
			email: "Sila masukkan alamat e-mel yang sah",
			password: "Kata laluan tidak mematuhi garis panduan",
			confirmPassword: "Pengesahan Kata Laluan tidak sepadan.",
			phoneNumber: "Nombor telefon bimbit diperlukan.",
			redemptionPoints: "Mata ganjaran tidak sah ",
			validityPeriod: "Tempoh sah mesti sama atau lebih besar dari satu",
			fileSize: "Saiz fail adalah terlalu besar",
			fileType: "Format fail tidak di sokong",
			fileLimit: "Format fail boleh menggunakan jpeg atau png dengan saiz maksima 5mb dan 5 gambar",
		},
		status: {
			all: "Semua",
			active: "Aktif",
			inactive: "Tidak aktif",
			pendingVerification: "Pengesahan yang belum selesai",
			expired: "Tamat Tempoh",
			completed: "Lengkap",
			moved: "Berpindah",
			passedAway: "Meninggal Dunia",
			none: "Tiada",
		},
		states: {
			all: "Semua",
			johor: "Johor",
			kedah: "Kedah",
			kelantan: "Kelantan",
			malacca: "melaka",
			negeriSembilan: "Negeri Sembilan",
			pahang: "Pahang",
			penang: "Penang",
			perak: "Perak",
			perlis: "Perlis",
			sabah: "Sabah",
			sarawak: "Sarawak",
			selangor: "Selangor",
			terengganu: "Terengganu",
			kualaLumpur: "Kuala Lumpur",
			labuan: "Labuan",
			putrajaya: "Putrajaya",
		},
		recipients: {
			customer: "Pelanggan",
			admin: "Pentadbir",
		},
	},
	components: {
		allocatePointsModal: {
			title: "Mata Kredit",
			field: [
				{ label: "Nombor Invois (Pilihan)", placeholder: "Masukkan Nombor Invois" },
				{ label: "Mata Kredit", placeholder: "Masukkan jumlah" },
			],
			cancel: "Batal",
			cta: "Kemas kini",
		},
		deactivateRewardModal: {
			title: "Nyahaktifkan ganjaran",
			description: "Adakah anda yakin untuk nyahaktifkan ganjaran ini",
			cancel: "Batal",
			cta: "Sahkan",
		},
		purchaseHistoryForm: {
			title: "Tambah Pembelian Baharu",
			field: [
				{
					label: "Nama Kedai",
					placeholder: "Pilih kedai",
				},
				{
					label: "Tarikh Pembelian",
					placeholder: "Pilih tarikh Pembelian",
				},
				{
					label: "Bilangan Pembelian",
					placeholder: "Bilangan pembelian",
				},
				{
					label: "Julat Preskripsi",
					placeholder: "Pilih julat preskripsi",
				},
				{
					label: "Indeks",
					placeholder: "Pilih indeks",
				},
				{
					label: "Kanta Dipreskripsikan",
					placeholder: "Kanta dipreskripsikan",
				},
				{
					label: "Aksesori Kanta",
					placeholder: "Pilih aksesori kanta",
				},
				{
					label: "Jenis Kanta",
					placeholder: "Jenis kanta",
				},
				{
					label: "CL Dipreskripsikan",
					placeholder: "CL dipreskripsikan",
				},
				{
					label: "Jenis CL",
					placeholder: "Jenis CL",
				},
				{
					label: "Bahan CL",
					placeholder: "Bahan CL",
				},
			],
			update: "Kemas kini",
			create: "Cipta",
			cancel: "Batal",
		},
		eyeExaminationForm: {
			title: "Tambah Pemeriksaan Mata Baharu",
			field: [
				{
					label: "Nama Kedai",
					placeholder: "Pilih nama kedai",
				},
				{
					label: "Tarikh Pemeriksaan",
					placeholder: "Pilih tarikh pemeriksaan",
				},
				{
					label: "Rx",
					placeholder: "Rx",
				},
				{
					label: "Kes/Temu janji",
					placeholder: "Pilih kes/temu janji",
				},
				{
					label: "Diperiksa oleh",
					placeholder: "Pilih diperiksa oleh",
				},
			],
			update: "Kemas kini",
			create: "Cipta",
			cancel: "Batal",
		},
		groupForm: {
			title: "Tambah Kumpulan Baharu",
			field: [{ label: "Nama Kumpulan", placeholder: "Nama Kumpulan" }],
			update: "Kemas kini",
			create: "Cipta",
			cancel: "Batal",
		},
		categoryForm: {
			field: [
				{ label: "Nama Kategori", placeholder: "Nama Kategori" },
				{ label: "Gambar Kategori", placeholder: "Menerima format fail JPEG atau PNG, saiz maksimum 5MB." },
				{ label: "Name Subkategori", placeholder: "Name Subkategori" },
				{ label: "Status", placeholder: "Status" },
			],
			uploadPicture: "Muat Naik Gambar",
			removePicture: "Padam Gambar",
			cancel: "Batal",
			update: "Kemas kini",
			create: "Cipta",
		},
		statusGroupModal: {
			inactive: {
				title: "Aktifkan",
				description: "Adakah anda yakin untuk aktifkan",
			},
			active: {
				title: "Nyahaktifkan",
				description: "Adakah anda yakin untuk nyahaktifkan",
			},
			cancel: "Batal",
			cta: "Mengesahkan",
		},
		subBannerModal: {
			title: "Tambah Sub-sepanduk Baharu",
			field: [
				{ label: "Tajuk Sub-sepanduk", placeholder: "Tajuk Sub-sepanduk" },
				{ label: "Gambar Sub-sepanduk", placeholder: "Menerima format fail JPEG atau PNG, saiz maksimum 5MB." },
				{ label: "Kandungan PDF", placeholder: "Menerima format fail PDF, saiz maksimum 5MB." },
				{ label: "Deskripsi", placeholder: "Deskripsi" },
			],
			tooltipsPDF: "PDF hanya dapat digunakan sekiranya tiada sub-sepanduk yang dimuat naik.",
			tooltips: "Deskripsi hanya dapat digunakan sekiranya tiada sub-sepanduk yang dimuat naik.",
			cancel: "Batal",
			update: "Kemas kini",
			create: "Cipta",
		},
		faqForm: {
			title: "Soalan Lazim Baharu",
			field: [
				{ label: "Tajuk Soalan-soalan Lazim", placeholder: "Tajuk Soalan-soalan Lazim" },
				{ label: "Deskripsi Soalan-soalan lazim", placeholder: "Jawapan untuk soalan-soalan lazim" },
			],
			cancel: "Batal",
			update: "Kemas kini",
			create: "Cipta",
		},
		faqRemoveModal: {
			title: "Keluarkan soalan yang kerap ditanya",
			description: "Adakah anda pasti untuk mengeluarkan soalan yang kerap ditanya",
			cancel: "Batal",
			cta: "Ya, keluarkan",
		},
		passwordForm: {
			title: "Tukar Kata Laluan",
			field: [
				{ label: "Kata Laluan Semasa", placeholder: "Masukkan Kata Laluan Semasa" },
				{ label: "Kata Laluan Baharu", placeholder: "Masukkan Kata Laluan Baharu" },
				{ label: "Pengesahan Kata Laluan Baharu", placeholder: "Masukkan Pengesahan Kata Laluan Baharu" },
			],
			editSuccess: "Kata laluan telah dikemas kini",
			cancel: "Batal",
			cta: "Kemas kini",
		},
		uploadInput: {
			pdfUpload: "Muat Naik PDF",
			pdfRemove: "Padam PDF",
			pictureUpload: "Muat Naik Gambar",
			pictureRemove: "Padam Gambar",
		},
		notificationModal: {
			title: "Notifikasi",
			cta: "Lihat Lagi",
		},
		unsavedBlocker: {
			title: "Keluar tanpa menyimpan",
			description: "Anda akan kehilangan kesemua maklumat yang tidak disimpan",
			cancel: "Batal",
			cta: "Ya, keluar",
		},
		logoutModal: {
			title: "Log Keluar",
			description: "Andalah anda pasti untuk log keluar?",
			cancel: "Batal",
			cta: "Log Keluar",
		},
		blockedModal: {
			title: "Akaun dinyahaktifkan.",
			description: "Akaun anda telah dinyahaktifkan. Jika anda mahu mengaktifkan semula akaun anda, sila hubungi kumpulan sokongan kami.",
			cta: "Okey",
		},
		textarea: {
			max: "(max {{currentChar}} / {{max}} aksara-aksara)",
		},
		pagination: {
			showing: "Tunjukkan 10 item setiap halaman",
			page: "Halaman",
			of: "di {{page}}",
		},
		time: {
			start: "Masa Mula",
			end: "Masa Tamat",
		},
	},
	notifications: {
		back: "Kebelakang",
	},
	signIn: {
		title: "Portal Pentadbiran",
		subTitle: "Log Masuk",
		forgotPassword: "Lupa Kata Laluan?",
		field: [
			{
				placeholder: "E-mel",
			},
			{
				placeholder: "Kata Laluan",
			},
		],
		error: "E-mel atau kata laluan salah",
		cta: "Log Masuk",
	},
	forgotPassword: {
		title: "Portal Pentadbiran",
		subTitle: "Lupa Kata Laluan",
		description: "Sila masukkan e-mel yang dikaitkan dengan akaun anda.",
		field: [
			{
				placeholder: "E-mel",
			},
		],
		cta: "Dapatkan Pautan Sementara",
	},
	resetPassword: {
		title: "Portal Pentadbiran",
		subTitle: "Tetapkan semula kata laluan",
		field: [
			{
				placeholder: "Masukkan kata laluan baharu",
			},
			{
				placeholder: "Sahkan kata laluan baharu",
			},
		],
		requirement: [
			{
				label: "Gunakan sekurang-kurangnya 8 aksara",
			},
			{
				label: "Mengandungi sekurang-kurangnya 1 huruf besar",
			},
			{
				label: "Mengandungi sekurang-kurangnya 1 angka",
			},
			{
				label: "Mengandungi sekurang-kurangnya 1 nombor",
			},
			{
				label: "Mengandungi sekurang-kurangnya 1 aksara istimewa",
			},
		],
		cta: "Tetapkan semula kata laluan",
	},
	emailSent: {
		title: "Portal Pentadbiran",
		subTitle: "Lupa Kata Laluan",
		description: "Sila semak e-mel anda untuk mendapatkan pautan sementara.",
		cta: "Selesai",
	},
	passwordUpdated: {
		title: "Portal Pentadbiran",
		subTitle: "Kata laluan dikemas kini",
		description: "Kata laluan anda berjaya ditetapkan semula. Sila guna kata laluan baharu anda untuk log masuk.",
		cta: "Log Masuk",
	},
	expiredLink: {
		title: "Portal Pentadbiran",
		subTitle: "Pautan Tamat Tempoh",
		description: "Pautan sementara anda telah tamat tempoh.",
	},
	notFound: {
		title: "Page not found",
	},
	customers: {
		title: "Pelanggan",
		showing: "Tunjukkan kesemua {{total}} hasil",
		search: "Carian melalui ID pengguna, e-mel pelanggan, nombor telefon pelanggan",
		table: {
			id: "ID Pengguna",
			name: "Nama",
			email: "E-mel",
			PhoneNumber: "Nombor Telefon Bimbit",
			points: "Mata ganjaran",
			status: "Status",
		},
	},
	customer: {
		breadCrumb: [{ label: "Senarai Pelanggan" }, { label: "Maklumat umum" }],
		tabs: [{ label: "Maklumat umum" }, { label: "Sejarah Transaksi Pembelian" }, { label: "Sejarah Pemeriksaan Mata" }, { label: "Sejarah mata ganjaran" }, { label: "Baucar" }],
		title: "Maklumat umum",
		creditPoints: "Mata Kredit",
		uploadCta: {
			view: "Paparan gambar profil",
			update: "Kemas kini gambar profil)",
		},
		field: [
			{
				label: "Mendaftar App",
				placeholder: "Mendaftar App?",
			},
			{
				label: "ID Pengguna",
				placeholder: "ID Pengguna",
			},
			{
				label: "Kod Keluarga & ID Pelanggan yang Berpindah",
				placeholder: "Kod Keluarga & ID Pelanggan yang Berpindah",
			},
			{
				label: "Nama Paparan Pengguna",
				placeholder: "Nama Paparan Pengguna",
			},
			{
				label: "Nama Penuh",
				placeholder: "Nama Penuh",
			},
			{
				label: "Alamat E-mel Berpindah",
				placeholder: "Alamat E-mel Berpindah",
			},
			{
				label: "Daftar Alamat E-mel",
				placeholder: "Daftar Alamat E-mel",
			},
			{
				label: "Tarikh Lahir",
				placeholder: "Tarikh Lahir",
			},
			{
				label: "Nombor Telefon Bimbit",
				placeholder: "Nombor Telefon Bimbit",
			},
			{
				label: "Alamat Baris 1",
				placeholder: "Contoh nombor unit, pangsapuri, suite",
			},
			{
				label: "Alamat Baris 2",
				placeholder: "Contoh jalan",
			},
			{
				label: "Poskod",
				placeholder: "Poskod",
			},
			{
				label: "Bandar",
				placeholder: "Bandar",
			},
			{
				label: "Negeri",
				placeholder: "Pilih Negeri",
			},
			{
				label: "Negara",
				placeholder: "Pilih Negara",
			},
			{
				label: "Pekerjaan",
				placeholder: "Pekerjaan",
			},
			{
				label: "Catatan",
				placeholder: "Catatan",
			},
			{
				label: "Tarikh Menyertai",
				placeholder: "Tarikh Menyertai",
			},
			{
				label: "Mata Ganjaran Semasa",
				placeholder: "Mata Ganjaran Semasa",
			},
			{
				label: "Status",
				placeholder: "Status",
			},
			{
				label: "Sub Status",
				placeholder: "Sub Status",
			},
		],
		success: "Pelanggan telah dikemas kini",
		pointsSuccess: "Peruntukkan mata ganjaran pelanggan telah dikemas kini",
		cancel: "Batal",
		cta: "Kemas kini",
	},
	purchaseHistory: {
		breadCrumb: [{ label: "Senarai Pelanggan" }, { label: "Maklumat umum" }],
		tabs: [{ label: "Maklumat umum" }, { label: "Sejarah Transaksi Pembelian" }, { label: "Sejarah Pemeriksaan Mata" }, { label: "Sejarah mata ganjaran" }, { label: "Baucar" }],
		title: "Sejarah Transaksi Pembelian",
		showing: "Tunjukkan kesemua {{total}} hasil",
		newCta: "Pembelian Baru",
		table: {
			purchaseDate: "Tarikh Pembelian",
			outlet: "Kedai",
		},
		editSuccess: "Sejarah pembelian pelanggan telah dikemas kini)",
		createSuccess: "Sejarah pembelian pelanggan telah dikemas kini",
		formTitle: "Butiran Transaksi Pembelian",
	},
	eyeExamination: {
		breadCrumb: [{ label: "Senarai Pelanggan" }, { label: "Maklumat umum" }],
		tabs: [{ label: "Maklumat umum" }, { label: "Sejarah Transaksi Pembelian" }, { label: "Sejarah Pemeriksaan Mata" }, { label: "Sejarah mata ganjaran" }, { label: "Baucar" }],
		title: "Sejarah Pemeriksaan Mata",
		showing: "Tunjukkan kesemua {{total}} hasil",
		newCta: "Sejarah Pemeriksaan Mata Baharu",
		table: {
			examinationDate: "Tarikh Pemeriksaan",
			outlet: "Kedai",
		},
		editSuccess: "Pemeriksaan mata pelanggan telah dilakukan",
		createSuccess: "Pemeriksaan mata pelanggan telah dilakukan",
		formTitle: "Butiran Pemeriksaan Mata)",
	},
	pointsHistory: {
		breadCrumb: [{ label: "Senarai Pelanggan" }, { label: "Maklumat umum" }],
		title: "Sejarah mata ganjaran",
		showing: "Tunjukkan kesemua {{total}} hasil",
		table: {
			date: "Tarikh Menyertai",
			transaction: "Transaksi",
			invoiceNumber: "Nombor Invois",
			updatedBy: "Dikemas kini oleh",
			totalPoints: "Jumlah Mata: {{points}}",
		},
	},
	vouchers: {
		breadCrumb: [{ label: "Senarai Pelanggan" }, { label: "Maklumat umum" }],
		title: "Baucar",
		showing: "Tunjukkan kesemua {{total}} hasil",
		table: {
			voucherCode: "Kod Baucar",
			voucherTitle: "Nama Baucar",
			status: "Status",
			dateUsed: "Tarikh Digunakan",
			redemptionDate: "Tarikh Penebusan",
			expiredDate: "Tarikh luput",
		},
	},
	loyaltyPrograms: {
		title: "Senarai Ganjaran",
		showing: "Tunjukkan kesemua {{total}} hasil",
		search: "Carian mengikut kod ganjaran, nama ganjaran",
		newCta: "Ganjaran Baharu",
		table: {
			code: "Kod Ganjaran",
			name: "Nama Ganjaran",
			createdDate: "Tarikh Dicipta",
			redemptionPoint: "Mata Penebusan",
			status: "Status",
		},
	},
	loyaltyProgram: {
		breadCrumb: [{ label: "Senarai Ganjaran" }, { label: "Ganjaran Baharu" }, { label: "Maklumat Ganjaran" }],
		title: "Maklumat Ganjaran",
		field: [
			{
				label: "Kod Ganjaran",
				placeholder: "Kod Ganjaran, cth: ABC, RAY, XMA",
			},
			{
				label: "Nama Ganjaran",
				placeholder: "Nama atau tajuk ganjaran",
			},
			{
				label: "Gambar Ganjaran",
				placeholder: "Menerima format fail JPEG atau PNG, saiz maksimum 5MB.",
			},
			{
				label: "Deskripsi",
				placeholder: "Terangkan tentang ganjaran",
			},
			{
				label: "Tajuk Baucar",
				placeholder: "Tajuk Baucar",
			},
			{
				label: "Tajuk Kecil Baucar",
				placeholder: "Tajuk Kecil Baucar",
			},
			{
				label: "Deskripsi Baucar",
				placeholder: "Deskripsi Baucar",
			},
			{
				label: "Mata Penebusan",
				placeholder: "Mata Penebusan",
			},
			{
				label: "Tarikh Mula",
				placeholder: "Pilih Tarikh Mula",
			},
			{
				label: "Tarikh Tamat",
				placeholder: "Pilih Tarikh Tamat",
			},
			{
				label: "Tempoh Sah (Hari)",
				placeholder: "Tempoh Sah (Hari)",
			},
			{
				label: "Status",
				placeholder: "status",
			},
		],
		editSuccess: "Ganjaran telah dikemas kini",
		createSuccess: "Ganjaran telah dicipta",
		cancel: "Batal",
		update: "Kemas kini",
		create: "Cipta",
	},
	groups: {
		title: "Senarai Kumpulan",
		showing: "Tunjukkan kesemua {{total}} hasil",
		newCta: "Kumpulan Baharu",
		groupEditSuccess: "Kumpulan telah dikemas kini",
		groupCreateSuccess: "Kumpulan telah dicipta",
		categoryEditSuccess: "Kategori telah dikemas kini",
		categoryCreateSuccess: "Kategori telah dicipta",
		variantEditSuccess: "Variasi telah dikemas kini",
		variantCreateSuccess: "variasi telah dicipta",
		newCategoryCta: "Kategori Baru",
		newSubCategoryCta: "Subkategori Baharu",
	},
	products: {
		title: "Produk",
		showing: "Tunjukkan kesemua {{total}} hasil",
		search: "Cari melalui Nama Produk",
		newCta: "Produk Baharu",
		table: {
			sku: "SKU",
			name: "Nama Produk",
			category: "Kategori",
			subcategory: "Subkategori",
			status: "Status",
		},
	},
	product: {
		breadCrumb: [{ label: "Produk" }, { label: "Produk telah dicipta" }, { label: "Maklumat Produk" }],
		title: "Maklumat Produk",
		field: [
			{
				label: "SKU",
				placeholder: "(autogenerasi sistem)",
			},
			{
				label: "Nama Produk",
				placeholder: "Nama Produk",
			},
			{
				label: "Kategori",
				placeholder: "Pilih Kategori",
			},
			{
				label: "Subkategori",
				placeholder: "Pilih Subkategori",
			},
			{
				label: "Gambar Produk",
				placeholder: "Menerima format fail JPEG atau PNG, saiz maksimum 5MB.",
			},
			{
				label: "Kandungan PDF",
				placeholder: "Menerima format fail PDF, saiz maksimum 5MB.",
			},
			{
				label: "Deskripsi",
				placeholder: "Terangkan produk anda.",
			},
			{
				label: "Status",
				placeholder: "Status",
			},
		],
		editSuccess: "Produk telah dikemas kini",
		createSuccess: "Produk telah dicipta",
		cancel: "Batal",
		update: "Kemas kini",
		create: "Cipta",
	},
	banners: {
		title: "Senarai Sepanduk",
		showing: "Tunjukkan kesemua {{total}} hasil",
		search: "Carian melalui tajuk sepanduk",
		newCta: "Sepanduk Baharu",
		table: {
			name: "Tajuk Sepanduk",
			status: "Status",
		},
	},
	banner: {
		breadCrumb: [{ label: "Senarai Sepanduk" }, { label: "Sepanduk Baharu" }, { label: "Maklumat Sepanduk" }],
		title: "Maklumat Sepanduk",
		newSubBanner: "Sub-sepanduk baharu",
		tooltipsPDF: "PDF hanya dapat digunakan sekiranya tiada sub-sepanduk yang dimuat naik.",
		tooltips: "Deskripsi hanya dapat digunakan sekiranya tiada sub-sepanduk yang dimuat naik.",
		field: [
			{
				label: "Tajuk Sepanduk",
				placeholder: "Tajuk Sepanduk",
			},
			{
				label: "Sub-sepanduk",
				placeholder: "Sub-sepanduk",
			},
			{
				label: "Gambar Sepanduk",
				placeholder: "Menerima format fail JPEG atau PNG, saiz maksimum 5MB.",
			},
			{
				label: "Kandungan PDF",
				placeholder: "Menerima format fail PDF, saiz maksimum 5MB.",
			},
			{
				label: "Deskripsi",
				placeholder: "Deskripsi",
			},
			{
				label: "Status",
				placeholder: "Status",
			},
		],
		editSuccess: "Sepanduk telah dikemas kini",
		createSuccess: "Sepanduk telah dicipta",
		cancel: "Batal",
		update: "Kemas kini",
		create: "Cipta",
	},
	outlets: {
		title: "Senarai Kedai",
		showing: "Tunjukkan kesemua {{total}} hasil",
		search: "Carian melalui code kedai, nama kedai, orang yang bertanggungjawab",
		newCta: "Kedai Baharu",
		table: {
			code: "Code Kedai",
			name: "Nama Kedai",
			pic: "Orang yang Bertanggungjawab",
			state: "Negeri",
			phone: "Nombor Telefon Kedai",
			status: "Status",
		},
	},
	outlet: {
		breadCrumb: [{ label: "Senarai Kedai" }, { label: "Kedai Baharu" }, { label: "Maklumat Kedai" }],
		title: "Maklumat Kedai",
		uploadPicture: "Muat Naik Gambar",
		removePicture: "Keluarkan gambar",
		field: [
			{
				label: "Code kedai ",
				placeholder: "Code Kedai",
			},
			{
				label: "Nama Kedai",
				placeholder: "Nama Kedai",
			},
			{
				label: "URL Laman Web",
				placeholder: "Pautan Laman Web",
			},
			{
				label: "Alamat Baris 1",
				placeholder: "Contoh nombor unit, pangsapuri, suite",
			},
			{
				label: "Alamat Baris 2",
				placeholder: "Contoh jalan",
			},
			{
				label: "Poskod",
				placeholder: "Poskod",
			},
			{
				label: "Bandar",
				placeholder: "Bandar",
			},
			{
				label: "Negeri",
				placeholder: "Pilih Negeri",
			},
			{
				label: "Negara",
				placeholder: "Pilih Negara",
			},
			{
				label: "Waktu Operasi",
				placeholder: "Waktu Operasi",
			},
			{
				label: "Gambar Kedai",
				placeholder: "Menerima format fail JPEG atau PNG, maksimum 5 gambar and gambar saiz maksimum 5MB.",
			},
			{
				label: "Nombor Telefon Kedai",
				placeholder: "Nombor Telefon Kedai",
			},
			{
				label: "Orang yang Bertanggungjawab",
				placeholder: "Orang yang Bertanggungjawab",
			},
			{
				label: "Status",
				placeholder: "Status",
			},
		],
		editSuccess: "Sepanduk telah dikemas kini",
		createSuccess: "Sepanduk telah dicipta",
		cancel: "Batal",
		update: "Kemas kini",
		create: "Cipta",
	},
	faqs: {
		title: "Senarai Soalan-soalan Lazim",
		showing: "Tunjukkan kesemua {{total}} hasil",
		removeSuccess: "Soalan yang kerap ditanya telah dikeluarkan",
		editSuccess: "Soalan yang kerap di tanya telah di kemas kini",
		createSuccess: "Soalan yang kerap di tanya telah dicipta",
		newCta: "Soalan Lazim Baharu",
	},
	announcements: {
		title: "Pengumuman",
		showing: "Tunjukkan kesemua {{total}} hasil",
		search: "Pencarian melalui tajuk pengumuman",
		newCta: "Pengumuman Baharu",
		table: {
			name: "Tajuk Pengumuman",
			date: "Tarikh dan Masa pengumuman",
		},
	},
	announcement: {
		breadCrumb: [{ label: "Pengumuman" }, { label: "Pengumuman Baharu" }, { label: "Maklumat Pengumuman" }],
		title: "Maklumat pengumuman",
		field: [
			{
				label: "Tajuk Pengumuman",
				placeholder: "Tajuk Pengumuman",
			},
			{
				label: "Deskripsi",
				placeholder: "Deskripsi Pengumuman",
			},
			{
				label: "Penerima",
				placeholder: "Penerima",
			},
		],
		editSuccess: "Pengumuman telah dikemas kini",
		createSuccess: "Pengumuman telah dicipta",
		cancel: "Batal",
		update: "Kemas kini",
		create: "Cipta",
	},
	settings: {
		tabs: [{ label: "Profil Saya" }, { label: "Meja Bantuan" }],
		title: "Profil Saya",
		editSuccess: "Profil telah dikemas kini",
		field: [
			{
				label: "ID Pentadbir",
				placeholder: "ID Pentadbir",
			},
			{
				label: "Nama",
				placeholder: "Nama",
			},
			{
				label: "Alamat E-mel",
				placeholder: "Alamat E-mel",
			},
			{
				label: "Peranan",
				placeholder: "Peranan",
			},
			{
				label: "Nombor Telefon",
				placeholder: "Nombor Telefon",
			},
			{
				label: "Nama Kedai",
				placeholder: "Nama Kedai",
			},
		],
		uploadCta: {
			view: "Paparan gambar profil",
			update: "Kemas kini gambar profil",
		},
		cancel: "Batal",
		update: "Kemas kini",
	},
	helpdesk: {
		tabs: [{ label: "Profil Saya" }, { label: "Meja Bantuan" }],
		title: "Pengurusan Meja Bantuan ",
		description: "Konfigurasi alamat e-mel anda untuk menerima pertanyaan pelanggan",
		editSuccess: "Meja bantuan telah dikemas kini",
		field: [
			{
				label: "Alamat E-mel",
				placeholder: "Alamat E-mel",
			},
		],
		cancel: "Batal",
		update: "Kemas kini",
	},
	users: {
		title: "Senarai Pentadbir",
		showing: "Tunjukkan kesemua {{total}} hasil",
		search: "Carian melalui ID pentadbir, nama pentadbir, nombor telefon pentadbir, nama kedai",
		newCta: "Pentadbir baharu",
		table: {
			name: "Nama Pentadbir",
			id: "ID Pentadbir",
			email: "E-mel",
			phoneNumber: "Nombor Telefon",
			store: "Nama Kedai",
			status: "Status",
		},
	},
	user: {
		breadCrumb: [{ label: "Senarai Pentadbir" }, { label: "Pentadbir baru" }, { label: "Admin Info" }],
		title: "Maklumat pentadbir",
		uploadCta: {
			view: "Paparan gambar profil",
			update: "Kemas kini gambar profil",
		},
		field: [
			{ label: "ID Pentadbir", placeholder: "ID Pentadbir" },
			{ label: "Name", placeholder: "Name" },
			{ label: "Alamat E-mel", placeholder: "Alamat E-mel" },
			{ label: "Peranan", placeholder: "Peranan" },
			{ label: "Nombor Telefon", placeholder: "Nombor Telefon" },
			{ label: "Nama Kedai", placeholder: "Nama Kedai" },
			{ label: "Status", placeholder: "Status" },
		],
		editSuccess: "Profil telah dikemas kini",
		createSuccess: "Profil telah dicipta",
		cancel: "Batal",
		update: "Kemas kini",
		create: "Cipta",
	},
};
export default bm;
