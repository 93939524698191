import React, { useCallback, memo } from "react";
import { Input as Field } from "reactstrap";
import numeral from "numeral";

export const moneyMask = (price) => {
	return numeral(price).format("0,0.00");
};

const AppCurrencyInput = ({ note, touched, error, icon, type, ...props }) => {
	const disabled = props.disabled;
	const invalid = touched && error;

	//prettier-ignore
	const onHandleBlur = useCallback((event) => {
		event.currentTarget.value = moneyMask(event.currentTarget.value.trim());
		props.onChange(event);
	}, [props]);

	return (
		<div className="app-input app-currency-input">
			<div className={getWrapperClassNames({ invalid, disabled })}>
				<p className="app-currency-input__label">RM</p>
				<Field {...props} className="app-currency-input__input" onBlur={onHandleBlur} autoComplete="off" />
			</div>

			{invalid && <p className="app-currency-input__text app-currency-input__text--error">{error}</p>}

			{note && <p className="app-currency-input__text">{note}</p>}
		</div>
	);
};

const getWrapperClassNames = ({ invalid, disabled }) => {
	const classNames = ["app-currency-input__container"];

	if (disabled) classNames.push("app-currency-input__container--disabled");

	if (invalid) classNames.push("app-currency-input__container--error");

	return classNames.join(" ");
};

export default memo(AppCurrencyInput);
