import React, { memo, useImperativeHandle, forwardRef, useMemo, useState, useCallback } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import AppButton from "components/app-button";
import AppTextarea from "components/app-textarea";
import { ReactComponent as FaqIcon } from "assets/images/pages/page-faqs/faq-icon.svg";

const initialValues = {
	note: "",
};

const AppAddNoteModal = forwardRef(({ isOpen, onHandleConfirm }, ref) => {
	const { t } = useTranslation("components");
	const [visible, setVisible] = useState(false);

	//prettier-ignore
	const validationSchema = useMemo(() => Yup.object().shape({
		note: Yup.string().required(t("common:required")),
	}), [t]);

	const formik = useFormik({
		enableReinitialize: isOpen,
		initialValues,
		validationSchema,
		onSubmit: (values, arg) => {
			arg.resetForm();
			onHandleDismiss();
			onHandleConfirm(values, enableSubmitButton);
		},
	});

	const enableSubmitButton = useCallback(() => {
		formik.setSubmitting(false);
	}, [formik]);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	const onHandleShow = useCallback(() => {
		setVisible(true);
	}, []);

	useImperativeHandle(ref, () => ({
		onHandleDismiss: onHandleDismiss,
		onHandleShow: onHandleShow,
	}));

	return (
		<Modal className="app-update-status-modal" contentClassName="update-status-modal" centered size="lg" isOpen={visible} toggle={onHandleDismiss}>
			<form autoComplete="off" onSubmit={formik.handleSubmit}>
				<ModalHeader className="update-status-modal__header" toggle={onHandleDismiss}></ModalHeader>
				<ModalBody className="update-status-modal__body">
					<div className="update-status-modal__body-header">
						<FaqIcon className="update-status-modal__icon" />
						<h1 className="update-status-modal__title">Update Note</h1>
					</div>

					<div className="update-status-modal__field">
						<p className="update-status-modal__label">Note</p>
						<AppTextarea name="note" placeholder="" error={formik.errors.note} touched={formik.touched.note} onChange={formik.handleChange} />
					</div>
				</ModalBody>
				<ModalFooter className="update-status-modal__footer">
					<AppButton type="button" label={t("faqForm.cancel")} cancel onClick={onHandleDismiss} />
					<AppButton type="submit" label={t("faqForm.update")} loading={formik.isSubmitting} />
				</ModalFooter>
			</form>
		</Modal>
	);
});

export default memo(AppAddNoteModal);
