const pathnames = {
	signIn: "/",
	// forgotPassword: "/forgot-password",
	// forgotPasswordEmailSent: "/email-sent",
	// resetPassword: "/reset-password",
	// passwordUpdated: "/password-updated",
	// expiredLink: "/expired-link",
	// customers: "/customers",
	// customer: "/customer/",
	// purchaseTransaction: "/customer/purchase-transaction/",
	// eyeExamination: "/customer/eye-examination/",
	// pointsHistory: "/customer/points-history/",
	// vouchers: "/customer/vouchers/",
	// groups: "/groups",
	// loyaltyPrograms: "/loyalty-programs",
	// loyaltyProgram: "/loyalty-program",
	// products: "/products",
	// product: "/product",
	// outlets: "/outlets",
	// outlet: "/outlet",
	// announcements: "/announcements",
	// announcement: "/announcement",
	// banners: "/banners",
	// banner: "/banner",
	// faqs: "/faqs",
	// settings: "/settings",
	// helpdesk: "/helpdesk",
	// users: "/users",
	// user: "/user",
	// notification: "/notification",
	dashboard: "/dashboard",
	leadMaintence: "/lead-maintenance",
	decisionMatrixMaintenanace: "/decision-matrix-maintenanace",
	matrixConfiguration: "/decision-matrix-config",
	newLead: "/new-lead",
	customer: "/customer",
	notFound: "/404",
};

export default pathnames;
